import './reservation-footer.scss';
import Button from '../../../../landing-page/components/button/button.component';
import {ReactComponent as Calendar} from '../../../../../assets/icons/calendar.svg';
import {ReactComponent as ChevronLeft} from '../../../../../assets/icons/chevron-left.svg';
import {ReactComponent as ChevronRight} from '../../../../../assets/icons/chevron-right.svg';
import {ReactComponent as Checkmark} from '../../../../../assets/icons/check.svg';

type Props = {
  step: number;
  onPress: () => void;
  onPressPrevious: () => void;
  onSubmit: () => void;
};

const ReservationFooter: React.FC<Props> = ({
  step,
  onPress,
  onPressPrevious,
  onSubmit,
}) => {
  const buttonIcon = (step: number) => {
    if (step === 0) {
      return Calendar;
    }
    if (step === 5) {
      return Checkmark;
    }
    return ChevronRight;
  };

  const buttonText = (step: number) => {
    if (step === 5) {
      return 'Reservering voltooien';
    }
    if (step > 0) {
      return 'Volgende stap';
    }
    return 'Reserveren';
  };

  return (
    <div className="reservation-footer">
      <div className="row">
        {step > 0 && (
          <Button
            onClick={onPressPrevious}
            className="no-fill"
            type="secondary"
            icon={ChevronLeft}
          />
        )}
        <Button
          className={`button ${step === 0 ? 'inner-button--reverse' : ''}`}
          type="primary"
          onClick={step <= 4 ? onPress : onSubmit}
          icon={buttonIcon(step)}
          iconClass={step > 0 ? 'icon--white' : undefined}>
          {buttonText(step)}
        </Button>
      </div>
    </div>
  );
};

export default ReservationFooter;
