import './donation-order-item.style.scss';

type Props = {
    title: string;
    amount: string;
}

const DonationOrderItemHeader: React.FC<Props> = ({ title, amount }) => (
    <div className={`donation_item`}>
        <h1 className={`donation_item_title`}>
            {title}
        </h1>
        {amount && <h2 className="donation_item_amount">
            {"€" + (parseInt(amount, 10) / 100).toFixed(2)}
        </h2>}
    </div>
)

export default DonationOrderItemHeader;