import React, {Component} from 'react';
import './message.dialog.style.scss';
import ModalDialog from '../../components/ui-base/modal-dialog/modal-dialog.component';

export default class MessageDialog extends Component {
  render() {
    const {title, description, onComplete} = this.props;

    return (
      <ModalDialog wrap={true}>
        <h1 className="message_dialog_title">{title}</h1>
        <h2 className="message_dialog_description">{description}</h2>

        <div className="message_dialog_options">
          <div className="flex-1" />
          <button className="message_dialog_button" onClick={onComplete}>
            Sluiten
          </button>
        </div>
      </ModalDialog>
    );
  }
}
