import Notification from '../../models/notification';
import {
  NEW_NOTIFICATION,
  NotificationsActions,
  REMOVE_NOTIFICATION,
} from '../actiontypes/notifications.actiontypes';

const initialState: Array<Notification> = [];

const NotificationsReducer = (
  state = initialState,
  action: NotificationsActions,
) => {
  switch (action.type) {
    case NEW_NOTIFICATION:
      return [...state, action.payload];
    case REMOVE_NOTIFICATION:
      return state
        .filter((item) => item.id !== action.payload.id)
        .map((item) => ({...item}));
    default:
      return state;
  }
};

export default NotificationsReducer;
