import Restaurant from '../../models/restaurant';
import NetworkState from '../network-state';

type State = {
  restaurants: Array<Restaurant>;
  previewRestaurant?: Restaurant | null;
  initialLoadingState: NetworkState;
  networkState: NetworkState;
  detailRestaurant?: Restaurant | null;
};

const initialState: State = {
  restaurants: [],
  initialLoadingState: NetworkState.IDLE,
  networkState: NetworkState.IDLE,
  detailRestaurant: null,
};

const RestaurantsReducer = (state = initialState, action: any): State => {
  switch (action.type) {
    case 'GET_RESTAURANTS':
      return {
        ...state,
        restaurants: [...action.payload],
      };
    case 'GET_RESTAURANT': {
      return {
        ...state,
        previewRestaurant: action.payload,
        detailRestaurant: action.payload,
      };
    }
    case 'RESTAURANTS_NETWORK_STATE': {
      return {
        ...state,
        networkState: action.payload,
      };
    }
    default:
      return state;
  }
};

export default RestaurantsReducer;
