import RestaurantLabel from '../../models/restaurantLabel';
import RestaurantMenuItem from '../../models/restaurantMenuItem';
import Filter from './filter';
import {
  ADD_FILTER,
  GET_LABELS,
  REMOVE_FILTER,
  SearchActions,
  SEARCH_MENU_RESULT,
} from './search.actiontypes';

export const addFilterAction = (filter: Filter): SearchActions => {
  return {
    type: ADD_FILTER,
    payload: filter,
  };
};

export const removeFilterAction = (filter: Filter): SearchActions => {
  return {
    type: REMOVE_FILTER,
    payload: filter,
  };
};

export const labelsRetrievedAction = (
  labels: Array<RestaurantLabel>,
): SearchActions => {
  return {
    type: GET_LABELS,
    payload: labels,
  };
};

export const menuItemsSearchResultAction = (
  menuItems: Array<RestaurantMenuItem>,
): SearchActions => {
  return {
    type: SEARCH_MENU_RESULT,
    payload: menuItems,
  };
};
