import {Typography} from '../../../../../../components/ui-base';
import Table from '../../../../../../models/table';
import ItemSelector from '../../item-selector/item-selector';
import {calculateAvailableLocations} from '../../reservation-helpers';
import './step4.scss';

type Props = {
  tables: Table[];
  amountOfPersons: number;
  preferredLocation?: string;
  setPreferredLocation: (location: string) => void;
};

const Step4: React.FC<Props> = ({
  tables,
  amountOfPersons,
  preferredLocation,
  setPreferredLocation,
}) => {
  return (
    <div className="step4">
      <ItemSelector<string>
        items={calculateAvailableLocations(tables, amountOfPersons)}
        selectedItem={preferredLocation}
        setSelectedItem={setPreferredLocation}
        noItemsText="Er is geen selectie beschikbaar"
        className="item-selector--1column"
      />
      <Typography className="extra">
        We proberen natuurlijk aan je voorkeur te kunnen voldoen, maar we kunnen
        dit niet altijd garanderen.
      </Typography>
    </div>
  );
};

export default Step4;
