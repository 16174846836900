import Avatar from '../../../../../assets/images/avatar.png';
import {Typography} from '../../../../../components/ui-base';
import {useReservationQuestion} from './use-reservation-question';
import './reservation-question.scss';

type Props = {
  step: number;
};

const ReservationQuestion: React.FC<Props> = ({step}) => {
  const question = useReservationQuestion(step);
  return (
    <div className="reservation-question">
      <img src={Avatar} alt="waiter" />
      <div className="speech">
        <Typography type="h6" headerClass="header6">
          {question}
        </Typography>
      </div>
    </div>
  );
};

export default ReservationQuestion;
