import classNames from 'classnames';
import React from 'react';
import {Typography} from '../../../../../components/ui-base';
import './item-selector.scss';

type Props<T> = {
  items: T[];
  selectedItem?: T;
  noItemsText?: string;
  setSelectedItem: (item: T) => void;
  className?: string;
};

const ItemSelector = <T extends unknown>({
  items,
  noItemsText,
  selectedItem,
  setSelectedItem,
  className = '',
}: Props<T>) => {
  const ItemSelectorClass = classNames('item-selector', {
    'item-selector--1column': items.length <= 1,
    'item-selector--2column': items.length === 2,
    'item-selector--3column': items.length === 3,
    'item-selector--4column': items.length === 4,
    'item-selector--fix-height': items.length >= 35,
  });

  const itemElements =
    items.length > 0 ? (
      items?.map((i) => (
        <div
          key={`${i}`}
          className={`item ${i === selectedItem && 'selected'}`}
          onClick={() => {
            setSelectedItem(i);
          }}>
          <Typography>{i}</Typography>
        </div>
      ))
    ) : (
      <Typography className="center">{noItemsText}</Typography>
    );

  return (
    <div className={`${ItemSelectorClass} ${className}`}>{itemElements}</div>
  );
};

export default ItemSelector;
