export class FilterType {
  static Label = new FilterType(false);
  static Mealtype = new FilterType(true);
  static Location = new FilterType(true);
  static Price = new FilterType(true);
  static Query = new FilterType(true);
  static Sort = new FilterType(true);
  static ReservationEnabled = new FilterType(true);

  /**
   * When true there can be only one of this filter type.
   */
  isSingle: Boolean;

  constructor(isSingle: Boolean) {
    this.isSingle = isSingle;
  }
}

export default class Filter {
  type: FilterType;
  value: string;
  displayValue: string;

  constructor(type: FilterType, value: string, displayValue: string) {
    this.type = type;
    this.value = value;
    this.displayValue = displayValue;
  }
}

/**
 * Dictates propeties for filters which are only used in a HTTP query.
 * They should be added to the query param mapping in the respective API request.
 */
export enum QueryType {
  Restaurant,
  Menu,
  Postalcode,
  Preview,
}
export class QueryFilter extends Filter {
  queryType: QueryType;

  constructor(queryType: QueryType, query: string) {
    super(FilterType.Query, query, query);
    this.queryType = queryType;
  }
}

export enum SortDirection {
  Ascending,
  Descending,
}
export class SortFilter extends Filter {
  static AlphabeticAscending = new SortFilter(
    SortDirection.Ascending,
    'Sorteer van A tot Z',
  );
  static AlphabeticDescending = new SortFilter(
    SortDirection.Descending,
    'Sorteer van Z tot A',
  );
  static Category = new SortFilter(
    SortDirection.Ascending,
    'Sorteer op categorie',
  );
  static Location = new SortFilter(
    SortDirection.Ascending,
    'Sorteer op plaats',
  );

  direction: SortDirection;

  constructor(direction: SortDirection, property: string) {
    super(FilterType.Sort, property, property);
    this.direction = direction;
  }
}
