import RestaurantLabel from '../../models/restaurantLabel';
import RestaurantMenuItem from '../../models/restaurantMenuItem';
import Filter from './filter';
import {
  SearchActions,
  ADD_FILTER,
  REMOVE_FILTER,
  GET_LABELS,
  SEARCH_MENU_RESULT,
} from './search.actiontypes';

type State = {
  activeFilters: Array<Filter>;
  // TODO: Type foundMenuItems
  foundMenuItems: Array<RestaurantMenuItem>;
  labels: Array<RestaurantLabel>;
};

const initialState: State = {activeFilters: [], foundMenuItems: [], labels: []};

const SearchReducer = (state = initialState, action: SearchActions): State => {
  switch (action.type) {
    case ADD_FILTER:
      if (action.payload.type.isSingle) {
        const filterIndex = state.activeFilters.findIndex(
          (x) => x.type === action.payload.type,
        );
        if (filterIndex > -1) {
          state.activeFilters.splice(filterIndex, 1);
        }
      }

      return {
        ...state,
        activeFilters: [...state.activeFilters, action.payload],
      };
    case REMOVE_FILTER: {
      const filterIndex = state.activeFilters.indexOf(action.payload);
      state.activeFilters.splice(filterIndex, 1);
      return {
        ...state,
        activeFilters: [...state.activeFilters],
      };
    }
    case GET_LABELS: {
      return {...state, labels: [...action.payload]};
    }
    case SEARCH_MENU_RESULT: {
      return {...state, foundMenuItems: action.payload};
    }

    default:
      return state;
  }
};

export default SearchReducer;
