import React, { CSSProperties, ReactNode } from 'react';
import './avatar.styles.scss';

type Props = {
  children?: ReactNode;
  containerStyle?: CSSProperties;
  onClick?: () => void;
  outerBorderColor?: string;
  innerBorderColor?: string;
  /** URL of the image to load */
  url: string;
};

const Avatar: React.FC<Props> = (props: Props) => {
  return (
    <div
      className="avatar"
      onClick={props.onClick}
      style={Object.assign(
        { borderColor: props.outerBorderColor },
        props.containerStyle,
      )}
    >
      <img
        src={props.url}
        alt="restaurant logo"
        style={{ borderColor: props.innerBorderColor }}
      />
      {props.children}
    </div>
  );
};

Avatar.defaultProps = {
  innerBorderColor: '#183f57',
  outerBorderColor: '#FFFFFF',
};

export default Avatar;
