export interface ReservationData {
  amountOfPersons?: number;
  reservationDate: Date;
  reservationTime?: string;
  preferredLocation?: string;
  customerDetails: CustomerDetails;
}

export interface CustomerDetails {
  name?: string;
  email?: string;
  phoneNumber?: number;
}

export enum ReservationChangeEventTypes {
  AmountOfPersons = 'amountOfPersons',
  ReservationDate = 'reservationDate',
  ReservationTime = 'reservationTime',
  PreferredLocation = 'preferredLocation',
  CustomerDetails = 'customerDetails',
}
