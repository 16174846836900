import _ from 'lodash';

export default class HistoricOrderDetails {
  itemId: string;
  image: string;
  title: string;
  subTitle: string;
  description: string;
  mainPrice: number;
  price: number;
  type: 'drinken' | 'eten';
  categoryId: string;
  category: string;
  ingredients: string;
  allergies: string;
  sideDishes: {[key: string]: any};
  comment: string;

  constructor(json: any) {
    this.itemId = json.itemId;
    this.image = json.image;
    this.title = json.title;
    this.subTitle = json.subTitle;
    this.description = json.description;
    this.price = json.price;
    this.mainPrice = json.mainPrice;
    this.type = json.type;
    this.categoryId = json.categoryId;
    this.ingredients = json.ingredients;
    this.allergies = json.allergies;
    this.category = json.category;
    this.sideDishes = json.sideDishes;
    this.comment = json.comment;
  }

  equals = (other: any) => {
    const equal = _.isEqual(
      _.omit(this, _.functions(this)),
      _.omit(other, _.functions(other)),
    );
    return equal;
  };
}
