import React from 'react';
import './legend-entry.scss';

type Props = {
  color: string;
  label: string;
};

const LegendEntry: React.FC<Props> = ({color, label}: Props) => {
  return (
    <div className="legend-entry">
      <span className="color" style={{backgroundColor: color}} />
      <span className="label">{label}</span>
    </div>
  );
};

export default LegendEntry;
