import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import React, {Component} from 'react';
import './table.header.style.scss';

export default class TableHeader extends Component {

    render() {
        const {tableNumber} = this.props;
        return (
            <div className="table_header">
                <div className="text">{`${tableNumber}`}</div>
                <EditIcon className="icon" fill="#BDC3C7" stroke="#BDC3C7"/>

            </div>
        );
    }
}
