import React from 'react';
import downloadAndroid from '../../../assets/images/google_play.png';
import downloadApple from '../../../assets/images/apple.png';
import header from '../../../assets/images/header_new.png';
import TopBar from '../top-bar/top-bar.component';
import SearchBar from './components/search-bar';
import LocationPicker from '../../../components/location-picker/location-picker.component';
import './top-section.scss';

type Props = {};

const TopSection: React.FC<Props> = () => {
  return (
    <>
      <TopBar />
      <div className="top-section">
        <div className="background">
          <img src={header} alt="homepage header" />
          <div className="gradient" />
        </div>
        <div className="container">
          <div className="leader">
            <h1>Ontdek de leukste horecazaken bij jou in de buurt!</h1>
            <p>
              Zoek op restaurantnaam, keuken of een gerecht waar je zin in hebt
              en vind het restaurant dat het beste bij je past!
            </p>
            <div className="button-holder">
              <SearchBar />
            </div>
            <div className="location-wrapper">
              <LocationPicker />
            </div>
          </div>
          <span className="download-buttons">
            <img
              src={downloadApple}
              onClick={() =>
                (window.location.href =
                  'https://apps.apple.com/us/app/amuse/id1507385684')
              }
              alt="App Store"
            />
            <img
              src={downloadAndroid}
              onClick={() =>
                (window.location.href =
                  'https://play.google.com/store/apps/details?id=com.livewall.amuse')
              }
              alt="Play Store"
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default TopSection;
