import React from 'react';
import {ReactComponent as Persons} from '../../../../../assets/icons/persons.svg';
import {ReactComponent as Calendar} from '../../../../../assets/icons/calendar-step.svg';
import {ReactComponent as Clock} from '../../../../../assets/icons/clock.svg';
import {ReactComponent as Checkmark} from '../../../../../assets/icons/checkmarrk.svg';
import {ReactComponent as ChevronRight} from '../../../../../assets/icons/chevron-right.svg';
import {ReactComponent as Location} from '../../../../../assets/icons/location-pin-black.svg';
import {Typography} from '../../../../../components/ui-base';
import './reservation-breadcrumbs.scss';

type Props = {
  step: number;
  texts: string[];
};

const stepIcons = [Persons, Calendar, Clock, Location, Checkmark];

const ReservationBreadcrumbs: React.FC<Props> = ({step, texts}) => {
  const steps = stepIcons?.map((Icon, index) => {
    const text = texts[index];
    const selected = index + 1 <= step;

    return (
      <div key={index} className={`step ${selected && 'selected'}`}>
        <div className="content">
          {text ? (
            <Typography type="span">{text}</Typography>
          ) : (
            <Icon className={selected ? 'selected-icon' : undefined} />
          )}
        </div>

        {index !== stepIcons.length - 1 && (
          <div
            className={`arrow-br ${
              selected && index + 1 !== step && 'bg-selected'
            }`}>
            {selected ? (
              <>
                {index + 1 === step ? (
                  <div className="selected-arrow" />
                ) : (
                  <ChevronRight fill="#FFFFFF" />
                )}
              </>
            ) : (
              <ChevronRight fill="#9E9E9E" />
            )}
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="breadcrumbs">
      <div className="holder">{steps}</div>
    </div>
  );
};

export default ReservationBreadcrumbs;
