import './loading.indicator.style.scss';

import React from 'react';

import classify from '../../utils/classify';

type Props = {
  isLoading: boolean;
  className?: string;
};

const LoadingIndicator: React.FC<Props> = (props: Props) => {
  var className = 'spinner';
  if (props.isLoading) {
    className += ' loading';
  }

  return (
    <div className={classify(className, props)}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LoadingIndicator;
