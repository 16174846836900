import AllergiesListItem from '../allergies-list-item/allergies.list.item.component';
import allergies from '../allergies/allergies.list';
import { Component } from 'react';
import './allergies.list.style.scss';

type Props = {
  activeAllergies: Array<string>;
  onAllergyToggled?: (allergy: any, newValue: boolean) => void;
  readonly: boolean;
};

class AllergiesList extends Component<Props> {
  render() {
    const { activeAllergies, readonly, onAllergyToggled } = this.props;

    let items = allergies.map((item) => {
      const active = activeAllergies
        ? !!activeAllergies.find((a) => item.id === a)
        : false;

      return { ...item, active: active };
    });

    if (readonly) {
      items = items.sort((a, b) => {
        return (b.active ? 1 : 0) - (a.active ? 1 : 0);
      });
    }

    const renderedAllergies = items.map((allergy, index) =>
      <AllergiesListItem
        key={index}
        readonly={readonly}
        name={allergy.name}
        color={allergy.color}
        active={allergy.active}
        icon={allergy.icon}
        onClick={(checked) => {
          if (onAllergyToggled && !readonly) {
            onAllergyToggled(allergy, checked);
          }
        }}
      />
    );

    return <div className="allergies_list_holder">{renderedAllergies}</div>;
  }
}

export default AllergiesList;
