import React from 'react';
import { Button } from '../ui-base';

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

const PinButton: React.FC<Props> = (props: Props) => {
  return (
    <Button
      disabled={props.disabled}
      text="Pin"
      className="mt-20 full-width"
      onClick={props.onClick}
    />
  );
};

export default PinButton;
