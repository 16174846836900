import React, {ReactNode} from 'react';
import './card-content.scss';

type Props = {
  children?: ReactNode;
  className?: string;
};

const CardContent: React.FC<Props> = (props: Props) => {
  return (
    <div className={`card-content ${props.className}`}>{props.children}</div>
  );
};

CardContent.defaultProps = {
  className: '',
};

export default CardContent;
