import './back-to-top.scss';
import {ReactComponent as CaretUp} from '../../assets/icons/caret-up.svg';

import { Component } from 'react'
import ReactVisibilitySensor from 'react-visibility-sensor';

type Props = {
    onVisibilityChange?: (isVisible: boolean) => void;
}

export class BackToTop extends Component<Props> {
    toTopClicked = () => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }

    render() {
        const {onVisibilityChange} = this.props;
        return (
            <ReactVisibilitySensor onChange={onVisibilityChange}>
                <div className="back-to-top" onClick={this.toTopClicked}>
                    <CaretUp width="20" height="20" />
                    <span>Terug naar boven</span>
                    <CaretUp width="20" height="20" />
                </div>
            </ReactVisibilitySensor>
        )
    }
}

export default BackToTop
