import React, {useState} from 'react';
import Timeslot from '../../models/timeslot';
import { InputGroup, Label, Select } from '../ui-base';

type Props = {
  slots: Array<Timeslot>;
  onSelectionChange: (selectedTimeSlot: Timeslot) => void;
};

const TimeslotSelector: React.FC<Props> = (props: Props) => {
  const {slots} = props;
  const [selectedSlot, setSelectedSlot] = useState<Timeslot>();

  let options;
  if (slots.length === 0 || slots.every((slot) => !slot.available)) {
    options = [
      <option key="no_time" value={undefined}>
        Geen tijd beschikbaar, kies een andere datum
      </option>,
    ];
  } else {
    options = [
      <option key="default" value={undefined}>
        Kies een tijd
      </option>,
      ...slots.map((x) => (
        <option
          key={x.when.format('HH:mm')}
          disabled={!x.available}
          value={x.when.format('HH:mm')}>
          {x.when.format('HH:mm')}
        </option>
      )),
    ];
  }

  return (
    <InputGroup>
      <Label>Tijdslot</Label>
      <Select
        className="full-width"
        disabled={slots.length === 0 || slots.every((slot) => !slot.available)}
        value={
          selectedSlot !== undefined
            ? selectedSlot.when.format('HH:mm')
            : undefined
        }
        onChange={(event) => {
          const selectedValue = props.slots[event.target.selectedIndex - 1];
          props.onSelectionChange(selectedValue);
          setSelectedSlot(selectedValue);
        }}>
        {options}
      </Select>
    </InputGroup>
  );
};

export default TimeslotSelector;
