import AspectRatioContainer from '../aspect-ratio-container/aspect-ratio-container.component';
import React, {Component, useEffect, useState} from 'react';
import './image.carousel.style.scss';

// const ImageCarouselFC = ({activeIndex}) => {
//   let slideRefs = [];
//   let sliderRef = React.createRef();

//   const [index, setIndex] = useState(activeIndex);

//   useEffect(() => {
// 		const sliderElement = this.sliderRef.current;
//     if (index === prevState.index && sliderElement && activeIndex !== index) {
//       const ref = this.slideRefs[activeIndex];
//       if (ref) {
//         sliderElement.scrollTo(ref.offsetLeft, 0);
//       }
//       this.setState({index: activeIndex});
//     }
// 	}, []);
// };

export default class ImageCarousel extends Component {
  slideRefs = [];
  sliderRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      index: props.activeIndex,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {activeIndex} = this.props;
    const {index} = this.state;
    const sliderElement = this.sliderRef.current;
    if (index === prevState.index && sliderElement && activeIndex !== index) {
      const ref = this.slideRefs[activeIndex];
      if (ref) {
        sliderElement.scrollTo(ref.offsetLeft, 0);
      }
      this.setState({index: activeIndex});
    }
  }

  handleSliderRef = (ref) => {
    this.sliderRef.current = ref;
    if (ref) {
      ref.addEventListener('scroll', this.handleSliderScrolled);
    }
  };

  handleSliderScrolled = (event) => {
    const {activeIndex, onActiveIndexChangeFromSlide} = this.props;
    const offset = event.target.scrollLeft;
    const windowWidth = window.innerWidth;
    const newActiveIndex = Math.ceil(offset / windowWidth);
    if (activeIndex !== newActiveIndex && onActiveIndexChangeFromSlide) {
      this.setState({index: newActiveIndex}, () => {
        onActiveIndexChangeFromSlide(newActiveIndex);
      });
    }
  };

  render() {
    const {activeIndex, items, inline, height} = this.props;

    const slides = items.map((item, index) =>
      this._renderSlide(item.image, index, activeIndex),
    );

    return (
      <div
        id="carousel-root"
        className={`image_carousel ${inline ? 'inline' : ''}`}>
        {height > 0 && this._renderFixedHeight(height, slides)}
        {!height && this._renderAspectRatio(slides)}
      </div>
    );
  }

  _renderFixedHeight = (height, slides) => {
    return (
      <div
        className="slides-container"
        style={{height: height, width: '100%'}}
        ref={this.handleSliderRef}>
        {slides}
      </div>
    );
  };

  _renderAspectRatio = (slides) => {
    return (
      <AspectRatioContainer
        ratio="56.25"
        className="slides-container"
        contentContainerRef={this.handleSliderRef}>
        {slides}
      </AspectRatioContainer>
    );
  };

  _renderSlide = (image, index) => {
    const {margin, inline} = this.props;

    const style = {};

    style.minWidth = `calc(100% - ${margin * 2}px)`;

    return (
      <div
        ref={(ref) => (this.slideRefs[index] = ref)}
        key={index}
        className={`slide ${inline ? 'inline' : ''}`}
        style={style}>
        <img
          className={`content ${inline ? 'inline' : ''}`}
          src={image}
          alt="walkthrough"
        />
      </div>
    );
  };
}
