import { logout } from '../../redux/actions/account.actions';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

type ReduxActions = {
    logout: () => void;
}

class Logout extends Component<ReduxActions & RouteComponentProps> {
    componentDidMount() {
        this.props.logout();
        this.props.history.push('/login');
    }

    render() {
        return (
            <></>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { logout })(Logout);
