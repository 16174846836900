import 'moment/locale/nl';
import HistoricSession from '../../models/history/historicSession';
import Restaurant from '../../models/restaurant';
import {OrderHistoryActions, SET_HISTORY} from './order-history.actiontypes';

type State = Array<HistoricSession>;

const initialState: State = [];

const SessionHistoryReducer = (
  state = initialState,
  action: OrderHistoryActions,
) => {
  switch (action.type) {
    case SET_HISTORY:
      return history(action.payload);
    default:
      return state;
  }
};

function history(payload: Array<any>) {
  const sessions: Array<any> = [];
  payload.forEach((restaurant) => {
    const parsedRestaurant = new Restaurant(restaurant);
    restaurant.sessions.forEach((session: any) => {
      sessions.push(new HistoricSession(session, parsedRestaurant));
    });
  });

  return sessions;
}

export default SessionHistoryReducer;
