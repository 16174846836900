import RestaurantMenuReducer from './restaurant.menu.reducer';
import RestaurantCategoriesReducer from './restaurant.categories.reducer';
import SessionHistoryReducer from '../order-history/session.history.reducer';
import NotificationsReducer from './notifications.reducer';
import RestaurantsReducer from './restaurants.reducer';
import AccountReducer from './account.reducer';
import SessionReducer from '../session/session.reducer';
import OrdersReducer from './orders.reducer';
import MenuReducer from './menu.reducer';
import {combineReducers, Action} from 'redux';
import OccupationReducer from '../occupation/occupation.reducer';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import ReservationsReducer from '../reservations/reservations.reducer';
import {useDispatch} from 'react-redux';
import SearchReducer from '../search/search.reducer';

const RootReducer = combineReducers({
  notifications: NotificationsReducer,
  menu: MenuReducer,
  reservations: ReservationsReducer,
  restaurants: RestaurantsReducer,
  restaurantMenu: RestaurantMenuReducer,
  restaurantCategories: RestaurantCategoriesReducer,
  orders: OrdersReducer,
  search: SearchReducer,
  session: SessionReducer,
  account: AccountReducer,
  occupation: OccupationReducer,
  sessionHistory: SessionHistoryReducer,
});

export default RootReducer;
export type AppState = ReturnType<typeof RootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  null,
  Action<string>
>;
export type AppThunkDispatch = ThunkDispatch<AppState, any, Action>;
export function useAppThunkDispatch(): AppThunkDispatch {
  return useDispatch<AppThunkDispatch>();
}
