import React from 'react';
import DialogImageHeader from '../../../components/dialog-image-header/dialog-image-header';
import { ActionsOrientation } from '../../../components/ui-base/modal-dialog-actions/modal-dialog-actions.component';
import Restaurant from '../../../models/restaurant';
import {useAppThunkDispatch} from '../../../redux/reducers/root.reducer';
import {setUserGreeted} from '../../../redux/session/session.thunks';
import imageUrl from '../../../utils/scalar';
import './user-greeting.dialog.style.scss';
import {
  Button,
  ModalDialogActions,
  ModalDialogDescription,
  ModalDialogTitle,
  ModalDialog,
} from '../../../components/ui-base';

type Props = {
  restaurant: Restaurant;
  onYesPressed: () => void;
  onNoPressed: () => void;
};

const UserGreetingDialog: React.FC<Props> = ({
  restaurant,
  onYesPressed,
  onNoPressed,
}: Props) => {
  const dispatch = useAppThunkDispatch();

  return (
    <ModalDialog className="user-greeting">
      <DialogImageHeader imageUrl={imageUrl(restaurant.profileImage)} />
      <ModalDialogTitle className="yes_no_dialog_title mt-20">
        {`Welkom bij ${restaurant.title}!`}
      </ModalDialogTitle>
      <ModalDialogDescription>
        Leuk dat je er bent. Wil je al vast iets te drinken bestellen?
      </ModalDialogDescription>
      <ModalDialogActions
        className="mt-40"
        orientation={ActionsOrientation.Horizontal}>
        <Button
          text="Ja lekker!"
          noIcon
          centerText
          onClick={() => {
            dispatch(setUserGreeted());
            onYesPressed();
          }}
        />
        <Button
          outline
          text="Nee, dank je"
          noIcon
          centerText
          onClick={() => {
            dispatch(setUserGreeted());
            onNoPressed();
          }}
        />
      </ModalDialogActions>
    </ModalDialog>
  );
};

export default UserGreetingDialog;
