import './aspect-ratio-container.style.scss';
import React from 'react';

type Props = {
    ratio: string;
    className?: string;
    containerClassnames?: string;
    contentContainerRef?: () => void;
}

const AspectRatioContainer: React.FC<Props> = (props) => {
    const { ratio, className, containerClassnames, contentContainerRef } = props;
    return (
        <div className={`aspect_ratio_container ${containerClassnames}`} style={{ paddingTop: `${ratio}%` }}>
            <div className={`aspect_ratio_container_wrapper ${className}`} ref={contentContainerRef}>
                {props.children}
            </div>
        </div>
    );
}

export default AspectRatioContainer;