import React, {useCallback, useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import Restaurant from '../../../../../../models/restaurant';
import Table from '../../../../../../models/table';
import Timeslot from '../../../../../../models/timeslot';
import ItemSelector from '../../item-selector/item-selector';
import {calculateTimeSlots} from '../../reservation-helpers';
import './step3.scss';

type Props = {
  reservationDate: Date;
  reservationTime?: string;
  amountOfPersons?: number;
  restaurant: Restaurant;
  tables: Table[];
  setReservationTime: (time: string) => void;
};

const Step3: React.FC<Props> = ({
  reservationDate,
  reservationTime,
  amountOfPersons,
  tables,
  restaurant,
  setReservationTime,
}) => {
  const [availableTimeslots, setAvailableTimeSlots] = useState<Timeslot[]>([]);

  const checkAvailability = useCallback(async () => {
    const timeSlots = await calculateTimeSlots(
      restaurant,
      reservationDate,
      amountOfPersons ?? 0,
      tables,
    );
    setAvailableTimeSlots(timeSlots ?? []);
  }, [amountOfPersons, reservationDate, restaurant, tables]);

  useEffect(() => {
    checkAvailability();
  }, [checkAvailability]);

  return (
    <div className="step3">
      <ItemSelector<string>
        items={availableTimeslots?.map((timeSlot) =>
          DateTime.fromISO(timeSlot.when.toISOString()).toFormat('HH:mm'),
        )}
        selectedItem={reservationTime}
        setSelectedItem={setReservationTime}
        noItemsText="Er is geen selectie beschikbaar"
      />
    </div>
  );
};

export default Step3;
