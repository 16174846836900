const initialState = {};

const RestaurantCategoriesReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case 'GET_MENU_CATEGORIES':
            newState = {...state};
            newState[action.payload.id] = [...action.payload.items];
            return newState;
        default:
            return state;
    }
};

export default RestaurantCategoriesReducer;
