import React from 'react';
import {useSelector} from 'react-redux';
import RestaurantMenuItem from '../../models/restaurantMenuItem';

import {
  addMenuFavorite,
  removeMenuFavorite,
} from '../../redux/actions/account.actions';
import {AppState, useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import {FavoriteIconButton} from '../favorite-icon-button/favorite-icon-button.component';

type Props = {
  className?: string;
  menuItem: RestaurantMenuItem;
};

export const MenuItemFavoriteButton: React.FC<Props> = (props: Props) => {
  const dispatch = useAppThunkDispatch();
  const favorites = useSelector<AppState, {[key: string]: boolean}>(
    (state) => state.account.menuFavorites,
  );
  const accountToken = useSelector<AppState, string>(
    (state) => state.account.token,
  );

  const {menuItem} = props;

  const addFavorite = () => {
    dispatch(addMenuFavorite(accountToken, menuItem.id));
  };
  const removeFavorite = () => {
    dispatch(removeMenuFavorite(accountToken, menuItem.id));
  };
  const isFavorite = favorites[menuItem.id];
  const clickHandler = isFavorite ? removeFavorite : addFavorite;

  return (
    <FavoriteIconButton
      favorited={isFavorite}
      className={props.className}
      onClick={clickHandler}
    />
  );
};
