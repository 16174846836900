import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import {ReactComponent as FilterIcon} from '../../assets/icons/filter.svg';
import React from 'react';
import './search.bar.style.scss';
import {RouteComponentProps, withRouter} from 'react-router-dom';

type Props = {} & RouteComponentProps;

const SearchBar: React.FC<Props> = ({history}) => {
  const openSearch = () => {
    history.push('/search');
  };

  const openFilter = () => {
    history.push('/filter');
  };

  return (
    <div className="searchbar">
      <div className="searchbar_input_container">
        <input
          className="searchbar_input"
          type="text"
          placeholder="Gerechten, restaurants of keukens"
          onClick={openSearch}
        />
        <SearchIcon width={22} height={22} className="searchbar_input_icon" />
      </div>
      <div className="filter-button">
        <FilterIcon className="searchbar_icon" onClick={openFilter} />
        <span className="filter-text">Filteren</span>
      </div>
    </div>
  );
};

export default withRouter(SearchBar);
