import Notification from '../../models/notification';

export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export type NewNotificationAction = {
  type: typeof NEW_NOTIFICATION;
  payload: Notification;
};

export type RemoveNotificationAction = {
  type: typeof REMOVE_NOTIFICATION;
  payload: Notification;
};

export type NotificationsActions =
  | NewNotificationAction
  | RemoveNotificationAction;
