import Logger from './logger';

class Resizer {
  static changeHeightWidth(height, maxHeight, width, maxWidth) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
    return {height, width};
  }

  static resizeAndRotateImage(
    image,
    maxWidth,
    maxHeight,
    compressFormat = 'jpeg',
    quality = 100,
    orientation,
  ) {
    const qualityDecimal = quality / 100;
    const canvas = document.createElement('canvas');

    let width = image.width;
    let height = image.height;

    const newHeightWidth = this.changeHeightWidth(
      height,
      maxHeight,
      width,
      maxWidth,
    );

    // set proper canvas dimensions before transform & export
    if (4 < orientation && orientation < 9) {
      canvas.width = newHeightWidth.height;
      canvas.height = newHeightWidth.width;
    } else {
      canvas.width = newHeightWidth.width;
      canvas.height = newHeightWidth.height;
    }

    const ctx = canvas.getContext('2d');

    switch (orientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, newHeightWidth.width, 0);
        break;
      case 3:
        ctx.transform(
          -1,
          0,
          0,
          -1,
          newHeightWidth.width,
          newHeightWidth.height,
        );
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, newHeightWidth.height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, newHeightWidth.height, 0);
        break;
      case 7:
        ctx.transform(
          0,
          -1,
          -1,
          0,
          newHeightWidth.height,
          newHeightWidth.width,
        );
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, newHeightWidth.width);
        break;
      default:
        break;
    }

    width = newHeightWidth.width;
    height = newHeightWidth.height;

    ctx.drawImage(image, 0, 0, width, height);

    return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal);
  }

  static createResizedImage(
    file,
    maxWidth,
    maxHeight,
    compressFormat,
    quality,
    responseUriFunc,
  ) {
    if (file) {
      const reader = new FileReader();
      this.getOrientation(file, (orientation) => {
        Logger.log(orientation);
        reader.readAsDataURL(file);
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            const resizedDataUrl = Resizer.resizeAndRotateImage(
              image,
              maxWidth,
              maxHeight,
              compressFormat,
              quality,
              orientation,
            );
            responseUriFunc(resizedDataUrl);
          };
        };
        reader.onerror = (error) => {
          responseUriFunc(error);
        };
      });
    } else {
      responseUriFunc('File Not Found');
    }
  }

  static getOrientation(file, callback) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const view = new DataView(e.target.result);
      if (view.getUint16(0, false) !== 0xffd8) {
        return callback(-2);
      }
      let length = view.byteLength,
        offset = 2;
      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return callback(-1);
          }

          const little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return callback(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return callback(-1);
    };
    reader.readAsArrayBuffer(file);
  }
}

const imageFileResizer = {
  imageFileResizer: (
    file,
    maxWidth,
    maxHeight,
    compressFormat,
    quality,
    responseUriFunc,
  ) => {
    return Resizer.createResizedImage(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      responseUriFunc,
    );
  },
};

export default imageFileResizer;
