import {useHistory, useLocation} from 'react-router-dom';
import React from 'react';

const reportBackHandled = (success) => {
  if (window.AppNative && window.AppNative.onBackButtonHandled) {
    window.AppNative.onBackButtonHandled(success);
  }
};

const AndroidBack = (props) => {
  const history = useHistory();
  const location = useLocation();
  if (window) {
    window.appBack = () => {
      if (location.pathname !== '/') {
        history.goBack();
        reportBackHandled(false);
      } else {
        reportBackHandled(true);
      }
    };
  }
  return <></>;
};

export default AndroidBack;
