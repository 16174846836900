import Notification, {NotificationType} from '../../models/notification';

export function showNotification(
  message: string,
  type: NotificationType,
  duration = 5000,
) {
  return async function (dispatch: any) {
    const notification = new Notification(message, type, duration);
    dispatch({
      type: 'NEW_NOTIFICATION',
      payload: notification,
    });

    await wait(duration);

    dispatch({
      type: 'REMOVE_NOTIFICATION',
      payload: notification,
    });
  };
}

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
