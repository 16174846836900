import React from 'react';
import './order.tip.style.scss';
import Formatters from '../../utils/formatters';

type Props = {
  price: number;
};

const OrderTip: React.FC<Props> = (props: Props) => {
  const {price} = props;
  return (
    <div className="tip">
      <div className="row">
        <div className="text">Fooi</div>
        <div className="flex-1" />
        <div className="text price">{Formatters.formatCurrency(price)}</div>
      </div>
    </div>
  );
};

export default OrderTip;
