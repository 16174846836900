import {Moment} from 'moment';

export default class Timeslot {
  available: boolean;
  when: Moment;

  constructor(when: Moment, available: boolean = true) {
    this.when = when;
    this.available = available;
  }

  clone = (): Timeslot => {
    return new Timeslot(this.when.clone(), this.available);
  };
}
