import OrderReservation from '../../../models/orderReservation';

export default class GetReservedOrdersResponse {
  reservations: Array<OrderReservation>;

  constructor(json: any) {
    if (json && Array.isArray(json)) {
      this.reservations = json.map((x: any) => new OrderReservation(x));
    } else {
      this.reservations = [];
    }
  }
}
