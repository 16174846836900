import headerDesktop from '../../../assets/images/header_desktop.jpg';

const DesktopHeader: React.FC = () => {
  return (
    <div
      style={{
        height: '250',
        backgroundImage: 'url(' + headerDesktop + ')',
        backgroundPosition: 'center',
      }}
    />
  );
};

export default DesktopHeader;
