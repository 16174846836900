import AspectRatioContainer from '../../../components/aspect-ratio-container/aspect-ratio-container.component';

import header from '../../../assets/images/header.png';

const MobileHeader: React.FC = () => {
  return (
    <AspectRatioContainer
      ratio="38.4"
      containerClassnames="dont-show-on-desktop">
      <img
        src={header}
        alt="header"
        className="full-image"
        width="100%"
        height="100%"
      />
    </AspectRatioContainer>
  );
};

export default MobileHeader;
