import React from 'react';
import RestaurantsQuickAction from '../restaurants-quickaction/restaurants-quickaction.component';
import './restaurants-quickactions.styles.scss';
import {ReactComponent as ReservationIcon} from '../../assets/icons/reservation-black.svg';
import {ReactComponent as ScanIcon} from '../../assets/icons/scan.svg';
import {ReactComponent as TakeoutIcon} from '../../assets/icons/takeout-black.svg';
import {ReactComponent as DeliveryIcon} from '../../assets/icons/delivery-black.svg';
import {useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import {addFilter} from '../../redux/search/search.thunks';
import {FilterType} from '../../redux/search/filter';

type Props = {};

const RestaurantsQuickActions: React.FC<Props> = (props: Props) => {
  const dispatch = useAppThunkDispatch();

  const addReservationFilter = () => {
    dispatch(addFilter(FilterType.ReservationEnabled, '1', 'Reserveren'));
  };

  const addTakeoutFilter = () => {
    dispatch(addFilter(FilterType.Mealtype, 'takeout', 'Afhalen'));
  };

  const addDeliveryFilter = () => {
    dispatch(addFilter(FilterType.Mealtype, 'delivery', 'Bezorgen'));
  };

  return (
    <div className="quick-actions-container">
      <RestaurantsQuickAction
        icon={ScanIcon}
        text="QR code scannen"
        link="scanner"
      />
      <RestaurantsQuickAction
        icon={ReservationIcon}
        text="Reserveren"
        onClick={addReservationFilter}
      />
      <RestaurantsQuickAction
        icon={TakeoutIcon}
        text="Afhalen"
        onClick={addTakeoutFilter}
      />
      <RestaurantsQuickAction
        icon={DeliveryIcon}
        text="Laten bezorgen"
        onClick={addDeliveryFilter}
      />
    </div>
  );
};

export default RestaurantsQuickActions;
