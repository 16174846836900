import Button from '../button/button.component';
import {ReactComponent as Arrow} from '../../../../assets/icons/chevron-right.svg';
import './block.scss';

type Props = {
  image: string;
  title: string;
  intro: string;
  ctaUrl: string;
  ctaLabel?: string;
};

const Block: React.FC<Props> = ({title, ctaUrl, ctaLabel, image, intro}) => {
  return (
    <div className="block">
      <img src={image} className="image" alt={title} />
      <h2 className="title">{title}</h2>
      <p className="intro">{intro}</p>
      <Button link={ctaUrl} type="tertiary" className="">
        {ctaLabel ?? `Meer info`} <Arrow />
      </Button>
    </div>
  );
};

export default Block;
