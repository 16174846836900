import ImageCarousel from '../../components/image-carousel/image.carousel.component';
import DotIndicator from '../../components/dot-indicator/dot.indicator.component';
import ImageHeader from '../../components/image-header/image-header.component';
import {ReactComponent as ActionIcon} from '../../assets/icons/back.svg';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import Content from '../../components/content/content.component';
import { Button } from '../../components/ui-base';
import header from '../../assets/images/menu_header.png';
import dish from '../../assets/images/dish.png';
import React, {Component} from 'react';
import './walkthrough.style.scss';
import Bg from '../../utils/bg';

export default class Walkthrough extends Component {
  storageKey = 'AMUSE_WALKTHROUGH_SEEN';
  steps = [
    {
      title: 'Stap 1',
      description:
        'Zoek naar het gerecht waar je zin in hebt of kies een restaurant uit het overzicht.',
      image: dish,
    },
    {
      title: 'Stap 2',
      description:
        'Wanneer je een persoonlijk profiel aanmaakt past de menukaart zich automatisch aan naar aanleiding van je allergieën.',
      image: dish,
    },
    {
      title: 'Stap 3',
      description:
        'Bestel de gerechten waar je zin in hebt, kies voor afhalen of bezorgen en vul de gevraagde gegevens in.',
      image: dish,
    },
    {
      title: 'Stap 4',
      description: 'Geniet van je diner, ook vanuit huis.',
      image: dish,
    },
  ];

  state = {
    activeIndex: 0,
    canSkip: localStorage.getItem(this.storageKey),
  };

  render() {
    const {activeIndex, canSkip} = this.state;
    const step = this.steps[activeIndex];
    const nextButtonText =
      activeIndex < this.steps.length - 1 ? 'Volgende slide' : 'Voltooien';

    const iconFill = activeIndex > 0 ? '#183f57' : '#ffffff';
    return (
      <>
        <Bg bg="bg-broken-white" />
        <Content>
          <ImageHeader header={header} ratio="64" />

          <div className="walkthrough_content">
            <div className="mt-20 mb-20 center" style={{width: 120}}>
              <Logo />
            </div>

            <ImageCarousel
              activeIndex={activeIndex}
              items={this.steps}
              margin={20}
              onActiveIndexChangeFromSlide={this.handleSlideChange}
            />

            <DotIndicator dots={this.steps.length} activeIndex={activeIndex} />

            <div className="title">{step.title}</div>
            <div className="description">{step.description}</div>
            <div className="action-buttons mb-20">
              {activeIndex> 0 && (
                <Button
                  outline={activeIndex > 0}
                  className="ml-20 mr-20 back-button"
                  onClick={this.prevSlide}
                  icon={<ActionIcon className="icon back-button-icon" {...iconFill} />}
                />
              )}
              <Button
                outline={activeIndex < this.steps.length - 1}
                className="ml-20 mr-20"
                text={nextButtonText}
                onClick={this.nextSlide}
              />
            </div>
            {canSkip && (
              <Button
                outline
                className="ml-20 mr-20"
                text="Overslaan"
                onClick={() => this.props.history.replace('/home')}
              />
            )}
          </div>
        </Content>
      </>
    );
  }

  handleSlideChange = (newSlide) => {
    this.setState({activeIndex: newSlide});
  };

  nextSlide = () => {
    const {activeIndex} = this.state;
    if (activeIndex === this.steps.length - 1) {
      localStorage.setItem(this.storageKey, 1);
      this.props.history.replace('/home');
      // navigate;
      return;
    }
    this.setState({activeIndex: activeIndex + 1});
  };

  prevSlide = () => {
    const {activeIndex} = this.state;
    if (activeIndex > 0) {
      this.setState({activeIndex: activeIndex - 1});
    }
  };
}
