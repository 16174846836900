import React, { ReactNode } from 'react';
import './modal-dialog-description.scss';

type Props = {
  className?: string;
  children: ReactNode;
};

const ModalDialogDescription: React.FC<Props> = (props: Props) => {
  const defaultClassName = 'modal-dialog-description';
  const customClasses = props.className!.split(' ');
  const classNames = [defaultClassName, ...customClasses].join(' ');
  return <h1 className={classNames}>{props.children}</h1>;
};

ModalDialogDescription.defaultProps = {
  className: '',
};

export default ModalDialogDescription;
