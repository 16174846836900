import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {ReactComponent as DeleteIcon} from '../../assets/icons/close.svg';
import {AppState, useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import Filter, {FilterType} from '../../redux/search/filter';
import {addFilter, removeFilter} from '../../redux/search/search.thunks';
import './price-range.styles.scss';

type Props = {
  onlyRenderSelected?: boolean;
};

const PriceRange: React.FC<Props> = ({onlyRenderSelected}) => {
  const [selected, setSelected] = useState(0);
  const dispatch = useAppThunkDispatch();
  const currentPriceRange = useSelector((state: AppState) =>
    state.search.activeFilters.find((x) => x.type === FilterType.Price),
  );
  useEffect(() => {
    if (currentPriceRange) {
      setSelected(parseInt(currentPriceRange.value));
    }
  }, [currentPriceRange]);

  const onClick = (index: number) => {
    setSelected(index);
    dispatch(addFilter(FilterType.Price, index.toString(), ''));
  };

  const reset = () => {
    dispatch(
      removeFilter(new Filter(FilterType.Price, selected.toString(), '')),
    );
    setSelected(0);
  };

  const icons = useMemo(() => {
    const items = [];
    for (let i = 0; i < 5; i++) {
      if (i < selected) {
        items.push(
          <span key={i} className="selected" onClick={() => onClick(i + 1)}>
            €
          </span>,
        );
      } else {
        items.push(
          <span key={i} onClick={() => onClick(i + 1)}>
            €
          </span>,
        );
      }
    }
    return items;
  }, [selected, onClick]);

  return (
    <div
      className={`price_range_container ${
        onlyRenderSelected ? '' : 'selectable'
      }`}>
      <div className="iconWrapper">
        {icons}
      </div>
      {selected > 0 && (
        <DeleteIcon onClick={reset} className="reset" fill="#BDC3C7" />
      )}
    </div>
  );
};

export default PriceRange;
