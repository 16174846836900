import React from 'react';
import './business-link.scss';

const BusinessLink: React.FC = () => {
  return (
    <a className="business_link" href={process.env.REACT_APP_BUSINESS_LINK}>
      Amuse voor horeca
    </a>
  );
};

export default BusinessLink;
