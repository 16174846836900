import React, {ReactNode} from 'react';
import './dialog.style.scss';

type Props = {
  className?: string;
  wrap?: boolean;
  center?: boolean;
  children: ReactNode;
};

const Dialog: React.FC<Props> = (props: Props) => {
  const {className, wrap, center} = props;

  const contentClassName = wrap
    ? 'dialog_content'
    : 'dialog_content full_height';
  return (
    <div className={'dialog ' + (center ? 'center' : '')}>
      <div className={contentClassName + ' ' + className}>{props.children}</div>
    </div>
  );
};

export default Dialog;
