import './middle-section.scss';
import React from 'react';
import Block from '../components/block/block.component';
import b2b from '../../../assets/images/b2b.png';
import tryNow from '../../../assets/images/try-now.png';

export const MiddleSection: React.FC = () => {
  return (
    <div className="middle-section">
      <div className="container">
        <Block
          image={tryNow}
          ctaUrl="/restaurants"
          ctaLabel="Begin nu!"
          title="Klaar voor een onvergetelijke uitje?"
          intro="Zoek en vind het restaurant dat écht bij je past, reserveer snel een plekje en maak er een onvergetelijk uitje van dat helemaal aansluit op jouw wensen en verwachtingen!"
        />
        <Block
          image={b2b}
          ctaUrl="/"
          title="Heb jij een horecazaak?"
          intro="Wil jij je gasten ook altijd een topavond laten beleven? Meld jouw horecazaak dan vandaag nog gratis aan op Amuse dan zorgen wij dat jij en jouw gasten binnen no-time Amuse kunnen gebruiken in jouw zaak."
        />
      </div>
    </div>
  );
};

export default MiddleSection;
