import Toolbar from '../../components/toolbar/toolbar.component';
import Content from '../../components/content/content.component';
import { loginFromAccountConfirm } from '../../redux/actions/account.actions';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { Component } from 'react';
import { connect } from 'react-redux';
import Bg from '../../utils/bg';
import Button from '../../components/ui-base/button/button.component';
import './account-confirmed.styles.scss';
import queryString from 'query-string';
import header from '../../assets/images/profile_header.png';
import ImageHeader from '../../components/image-header/image-header.component';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../redux/reducers/root.reducer';
import APIResponse from '../../services/api/responses/APIResponse';
import GetProfileResponse from '../../services/api/responses/getProfileResponse';

type ReduxActions = {
  loginFromAccountConfirm: (
    token: string | string[],
    sessionId?: string,
    sessionToken?: string
  ) => Promise<APIResponse<GetProfileResponse>>
}

type Props = {} & RouteComponentProps & ReduxActions

class AccountConfirm extends Component<Props> {
  state = {
    text: '',
    action: 'home',
    actionText: 'Naar Amuse!',
  };

  /* 
  * Check the query parameters if account is activated succesfully
  * @return when there are no parameters given, redirect to '/'
  */
  componentDidMount = () => {
    const parsedQuery = queryString.parse(this.props.location.search);
    if (parsedQuery.success === '0') {
      if (parsedQuery.error === 'emailAlreadyConfirmed') {
        this.setState({
          text: 'Je account is al geactiveerd. Ga naar het login scherm om in te loggen.',
          action: 'login',
          actionText: 'Inloggen',
        });
      }
    } else if (parsedQuery.token) {
      const token = parsedQuery.token;
      this.props.loginFromAccountConfirm(token);
      this.setState({ text: 'Je account is nu geactiveerd!' });
    } else {
      this.props.history.push('/');
    }
  };

  onComplete = () => {
    const { history } = this.props;
    const { action } = this.state;

    if (action === 'home') {
      history.push('/home');
    } else if (action === 'login') {
      history.push('/login');
    }
  };

  render() {
    const { actionText, text } = this.state;

    return (
      <>
        <Toolbar title="Account geactiveerd" />
        <Content toolbar={true} className="full-width">
          <Bg bg="bg-white" />
          <ImageHeader header={header} ratio="38.4" />
          <div
            className="mt-20 center account_confirm_logo"
            style={{ width: 160 }}>
            <Logo />
          </div>
          <h1 className="account_confirm_greeting">{text}</h1>

          <Button
            text={actionText}
            className="btn-submit"
            onClick={this.onComplete}
          />
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {};
};

export default connect(mapStateToProps, { loginFromAccountConfirm })(
  AccountConfirm,
);
