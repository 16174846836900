import './restaurant-popup.scss';

import React, {useCallback} from 'react';
import {Popup} from 'react-leaflet';
import {useHistory} from 'react-router-dom';

import {ReactComponent as LocationIcon} from '../../assets/icons/location.svg';
import Restaurant from '../../models/restaurant';
import imageUrl from '../../utils/scalar';
import PriceRange from '../price-range/price-range.component';
import {Button, Divider} from '../ui-base';

type Props = {
  restaurant: Restaurant;
};

export const RestaurantPopup: React.FC<Props> = (props: Props) => {
  const {restaurant} = props;
  const address = `${restaurant.street} ${restaurant.streetNumber}, ${restaurant.postalCode}, ${restaurant.city}`;

  const history = useHistory();
  const onOpenRestaurant = useCallback(() => {
    history.push('/restaurant/' + restaurant.generateSlug());
  }, [history, restaurant]);

  const onOpenAddress = useCallback(() => {
    window.location.href =
      'https://www.google.com/maps/search/?api=1&query=' + address;
  }, [address]);

  return (
    <Popup className="restaurant-popup" autoPan={true} keepInView={true}>
      <div className="header">
        <h1>{restaurant.title}</h1>
        <PriceRange value={parseInt(restaurant.pricing)} />
      </div>
      <span className="location" onClick={onOpenAddress}>
        <LocationIcon className="location-icon" />
        <span>{address}</span>
      </span>
      <img
        alt=""
        className="profile-image"
        src={imageUrl(restaurant.profileImage)}
      />
      <Divider />
      <Button text="Naar restaurant" onClick={onOpenRestaurant} />
    </Popup>
  );
};
