import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import {removeFilter} from '../../../redux/search/search.thunks';
import classify from '../../../utils/classify';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import './active-filter.style.scss';
import PriceRange from '../../price-range/price-range.component';
import Filter, {FilterType} from '../../../redux/search/filter';
import Formatters from '../../../utils/formatters';

type ReduxActions = {
  removeFilter: (toRemove: Filter) => void;
};

type Props = {
  className?: string;
  filter: Filter;
} & ReduxActions;

class ActiveFilterView extends Component<Props> {
  renderPriceRangeFilter = () => {
    const {filter} = this.props;
    return (
      <span className="filter_price">
        <span className="filter_price_label">Prijsklasse:</span>
        <PriceRange value={parseInt(filter.value)} onlyRenderSelected />
      </span>
    );
  };

  renderTextFilter = () => {
    const {filter} = this.props;
    const title = filter.displayValue;

    return <span className="filter_text">{title}</span>;
  };

  render() {
    const {filter} = this.props;
    return (
      <div className={classify('filter', this.props)}>
        {filter.type === FilterType.Price
          ? this.renderPriceRangeFilter()
          : this.renderTextFilter()}
        <CloseIcon className="filter_close" onClick={this.remove} />
      </div>
    );
  }

  remove = () => {
    const {filter} = this.props;
    this.props.removeFilter(filter);
  };
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {removeFilter})(ActiveFilterView);
