import profile_placeholder from '../../assets/images/profile_placeholder.png';
import {ReactComponent as EditIcon} from '../../assets/icons/edit2.svg';
import {uploadProfileImage} from '../../redux/actions/account.actions';
import Resizer from '../../utils/resizer';
import React, {useRef, useCallback} from 'react';
import './profile.header.style.scss';
import {connect} from 'react-redux';
import Avatar from '../avatar/avatar.component';

type ReduxProps = {
  profileImage?: string;
  account?: any;
};

type InputProps = {
  name: string;
  onHistoryClicked: () => void;
  uploadProfileImage: (token: any, image: any) => void;
};

type Props = ReduxProps & InputProps;

const ProfileHeader: React.FC<Props> = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  let image = profile_placeholder;
  if (props.profileImage && props.profileImage.length > 0) {
    image = props.profileImage;
  }

  const uploadProfileImage = useCallback(() => {
    if (!inputRef.current) return;

    inputRef.current.click();
  }, [inputRef]);

  const onProfileImageChange = useCallback(
    (event: any) => {
      if (!event.target.files || event.target.files.length === 0) {
        return;
      }

      Resizer.imageFileResizer(
        event.target.files[0],
        500,
        500,
        'JPEG',
        95,
        (uri: any) => {
          const {account} = props;
          props.uploadProfileImage(account.token, uri);
        },
      );
    },
    [props],
  );

  return (
    <div className="profile_header login_header">
      <Avatar
        url={image}
        onClick={uploadProfileImage}
        innerBorderColor="#FFFFFF"
        outerBorderColor="#183f57">
        <div className="profile_picture_edit">
          <EditIcon
            className="profile_picture_edit_icon"
            fill="white"
            stroke="white"
          />
        </div>
      </Avatar>
      <input
        ref={inputRef}
        className="hide"
        type="file"
        accept="image/*"
        alt="profile picture"
        onChange={onProfileImageChange}
      />
      <div className="profile_title">{props.name}</div>
      <div className="profile_order_history" onClick={props.onHistoryClicked}>
        Bestelgeschiedenis
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    account: state.account,
    profileImage: state.account.profileImage,
  };
};

export default connect(mapStateToProps, {uploadProfileImage})(ProfileHeader);
