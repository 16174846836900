import React, {ReactNode} from 'react';
import './card-header.scss';

type Props = {
  children?: ReactNode;
};

const CardHeader: React.FC<Props> = (props: Props) => {
  return <div className="card-header">{props.children}</div>;
};

export default CardHeader;
