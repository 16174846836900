import classify from '../../../utils/classify';
import React, {Component} from 'react';
import './input.style.scss';

export type InputProps = {
  className?: string;
  invalid?: boolean;
  prefix?: string;
  noUnderline?: boolean;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export default class Input extends Component<InputProps> {
  render() {
    const {invalid, prefix} = this.props;
    const propsToPass = {...this.props};
    delete propsToPass['className'];
    delete propsToPass['invalid'];
    delete propsToPass['prefix'];

    return (
      <div
        className={`input ${this.props.noUnderline ? 'hide-underline' : ''}`}>
        {prefix && <span className="prefix">{prefix}</span>}
        <input
          className={classify(`input ${invalid ? 'invalid' : ''}`, this.props)}
          {...propsToPass}
        />
      </div>
    );
  }
}
