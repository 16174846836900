import Content from '../../components/content/content.component';
import Toolbar from '../../components/toolbar/toolbar.component';
import './privacy-policy.styles.scss';

const PrivacyPolicyPage: React.FC = ({}) => {
  return (
    <>
      <Toolbar showMenuIcon={true} title="Privacy beleid" />

      <Content toolbar={true} className="privacy-policy">
        <div className="full-width">
          <p>
            Amuse, gevestigd aan J.F. Vlekkeweg 10-13 5026RJ Tilburg, is
            verantwoordelijk voor de verwerking van persoonsgegevens zoals
            weergegeven in deze privacyverklaring. Contactgegevens:
            https://www.amuse-menu.nl J.F. Vlekkeweg 10-13 5026RJ Tilburg
            +31646979054 R. van Buren is de Functionaris Gegevensbescherming van
            Amuse Hij/zij is te bereiken via remco@amuse-menu.nl
          </p>
          <h2>Persoonsgegevens die wij verwerken</h2>
          <p>
            Amuse verwerkt je persoonsgegevens doordat je gebruik maakt van onze
            diensten en/of omdat je deze gegevens zelf aan ons verstrekt.
            Hieronder vind je een overzicht van de persoonsgegevens die wij
            verwerken uitgesplitst naar de <b>categorie betrokkenen</b> / de
            groep mensen over wie de gegevens gaan zijn.
          </p>
          De categorie betrokkenen zijn:
          <ol>
            <li>
              A. Particuliere eindgebruikers van het platform (natuurlijke
              gebruikers in de vorm van een consument).{' '}
            </li>
            <li>
              B. Zakelijke eindgebruikers/klanten van het platform (ondernemers
              in de vorm van horeca ondernemers of personen die werkzaam zijn in
              de horeca).
            </li>
          </ol>
          <p>
            Het <b>doel</b> van de verwerking van de persoonsgegevens van de
            particuliere eindgebruiker is het personaliseren van de menukaart,
            afgestemd op voorkeuren en allergieën van de particuliere
            eindgebruiker. Daarnaast worden bij ‘bestellingen voor afhalen en/of
            bezorging’ persoonsgegevens verwerkt om de bestelling te kunnen
            afhandelen. Verder stelt de registratie pagina voor zakelijke
            eindgebruikers Amuse in staat om leads te genereren voor de
            zakelijke gebruikers/klanten van het platform. Gebruikers van de
            aanmeldpagina zijn zich ervan bewust dat de gegevens die zij
            achterlaten worden gebruikt als “contactinformatie”.
          </p>
          <p>
            Persoonsgegevens die verwerkt worden zijn:
            <ol>
              <li>A. Particuliere eindgebruikers (consumenten)</li>
              <ul>
                <p>1. mbt persoonlijk profiel</p>
                <li>Voornaam</li>
                <li>E-mailadres</li>
                <li>Bestellingen (historie)</li>
              </ul>
              <p>OF</p>
              <ul>
                <li>Voornaam</li>
                <li>Facebook ID</li>
                <li>Bestellingen (historie)</li>
                <li>
                  Overige persoonsgegevens die je actief verstrekt bijvoorbeeld
                  door een profiel op deze website aan te maken
                </li>
                <li>Gegevens over jouw activiteiten op onze website</li>
              </ul>
              <ul>
                <p>2. mbt bestellingen voor bezorgen en/of afhalen</p>
                <li>Voornaam en achternaam</li>
                <li>E-mailadres</li>
                <li>Telefoonnummer</li>
                <li>Adres</li>
              </ul>
            </ol>
            <ol>
              <li>
                B. Zakelijke eindgebruikers (horecaondernemers of personen
                werkzaam in de horeca)
                <ul>
                  <li>Naam onderneming</li>
                  <li>Naam ondernemer</li>
                  <li>Email</li>
                  <li>Telefoonnummer</li>
                  <li>Adres</li>
                  <li>Postcode</li>
                  <li>Plaats</li>
                  <li>Persoonlijk bericht</li>
                </ul>
              </li>
            </ol>
          </p>
          <h2>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h2>
          <p>
            Amuse verwerkt de volgende bijzondere en/of gevoelige
            persoonsgegevens van jou indien je deze toevoegt aan je
            profielinformatie:
          </p>
          <ul>
            <li>
              Gezondheid (allergie informatie van particuliere eindgebruikers)
            </li>
          </ul>
          <h2>
            Met welk doel en op basis van welke grondslag wij persoonsgegevens
            verwerken
          </h2>
          <p>Amuse verwerkt jouw persoonsgegevens voor de volgende doelen:</p>
          <ul>
            <li>Je de mogelijkheid te bieden een account aan te maken</li>
            <li>Om goederen en diensten bij je af te leveren</li>
            <li>
              Amuse analyseert jouw gedrag op de website om daarmee de website
              te verbeteren en het aanbod van producten en diensten af te
              stemmen op jouw voorkeuren.
            </li>
          </ul>
          <h2>Geautomatiseerde besluitvorming</h2>
          <p>
            Amuse neemt niet op basis van geautomatiseerde verwerkingen
            besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor
            personen. Het gaat hier om besluiten die worden genomen door
            computerprogramma's of -systemen, zonder dat daar een mens
            (bijvoorbeeld een medewerker van Amuse) tussen zit.
          </p>
          <h2>Hoe lang we persoonsgegevens bewaren</h2>
          <p>
            Amuse bewaart je persoonsgegevens niet langer dan strikt nodig is om
            de doelen te realiseren waarvoor je gegevens worden verzameld. Wij
            hanteren de volgende bewaartermijnen voor de volgende (categorieën)
            van persoonsgegevens:
          </p>
          <p>
            De persoonsgegevens mbt de categorie “persoonsgegevens particuliere
            eindgebruiker voor persoonlijk profiel” worden bewaard zolang de
            eindgebruiker actief gebruik maakt van het platform en/of app. Met
            actief gebruik maken wordt bedoeld ‘ingelogd zijn met het
            persoonlijk profiel’. Indien de eindgebruiker 2 jaar niet ingelogd
            heeft zullen de persoonsgegevens verwijderd worden.
          </p>
          <p>
            De persoonsgegevens mbt de categorie “persoonsgegevens particuliere
            eindgebruiker voor afhandeling bestelling afhalen en/of bezorgen”
            worden bewaard voor 7 dagen na het plaatsen van de bestelling.
            Hierna worden de gegevens automatisch verwijderd.
          </p>
          <p>
            De persoonsgegevens mbt de categorie “persoonsgegevens zakelijke
            eindgebruiker” worden bewaard zolang de eindgebruiker actief gebruik
            maakt van het platform en/of app. Met actief gebruik maken wordt
            bedoeld ‘ingelogd zijn met het persoonlijk profiel’. Indien de
            eindgebruiker 2 jaar niet ingelogd heeft zullen de persoonsgegevens
            verwijderd worden.
          </p>
          <h2>Delen van persoonsgegevens met derden</h2>
          <p>
            Amuse verkoopt jouw gegevens niet aan derden en zal deze uitsluitend
            verstrekken indien dit nodig is voor de uitvoering van onze
            overeenkomst met jou of om te voldoen aan een wettelijke
            verplichting. Met bedrijven die jouw gegevens verwerken in onze
            opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor
            eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw
            gegevens. Amuse blijft verantwoordelijk voor deze verwerkingen.
          </p>
          <h2>Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
          <p>
            Amuse gebruikt functionele, analytische en tracking cookies. Een
            cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
            website wordt opgeslagen in de browser van je computer, tablet of
            smartphone. Amuse gebruikt cookies met een puur technische
            functionaliteit. Deze zorgen ervoor dat de website naar behoren
            werkt en dat bijvoorbeeld jouw voorkeursinstellingen onthouden
            worden. Deze cookies worden ook gebruikt om de website goed te laten
            werken en deze te kunnen optimaliseren. Daarnaast plaatsen we
            cookies die jouw surfgedrag bijhouden zodat we op maat gemaakte
            content en advertenties kunnen aanbieden. Bij jouw eerste bezoek aan
            onze website hebben wij je al geïnformeerd over deze cookies en
            hebben we je toestemming gevraagd voor het plaatsen ervan. Je kunt
            je afmelden voor cookies door je internetbrowser zo in te stellen
            dat deze geen cookies meer opslaat. Daarnaast kun je ook alle
            informatie die eerder is opgeslagen via de instellingen van je
            browser verwijderen. Zie voor een toelichting:
            <a href="https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-heten-wat-doe-ik-ermee/">
              https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-heten-wat-doe-ik-ermee/
            </a>
          </p>
          <h2>Gegevens inzien, aanpassen of verwijderen</h2>
          <p>
            Je hebt het recht om je persoonsgegevens in te zien, te corrigeren
            of te verwijderen. Daarnaast heb je het recht om je eventuele
            toestemming voor de gegevensverwerking in te trekken of bezwaar te
            maken tegen de verwerking van jouw persoonsgegevens door Amuse en
            heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je
            bij ons een verzoek kan indienen om de persoonsgegevens die wij van
            jou beschikken in een computerbestand naar jou of een ander, door
            jou genoemde organisatie, te sturen. Je kunt een verzoek tot inzage,
            correctie, verwijdering, gegevensoverdraging van je persoonsgegevens
            of verzoek tot intrekking van je toestemming of bezwaar op de
            verwerking van jouw persoonsgegevens sturen naar
            remco@amuse-menu.nl. Om er zeker van te zijn dat het verzoek tot
            inzage door jou is gedaan, vragen wij jou een kopie van je
            identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie
            je pasfoto, MRZ (machine readable zone, de strook met nummers
            onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN)
            zwart. Dit ter bescherming van je privacy. We reageren zo snel
            mogelijk, maar binnen vier weken, op jouw verzoek . Amuse wil je er
            tevens op wijzen dat je de mogelijkheid hebt om een klacht in te
            dienen bij de nationale toezichthouder, de Autoriteit
            Persoonsgegevens. Dat kan via de volgende link:
            <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-deautoriteit-persoonsgegevens/tip-ons">
              https://autoriteitpersoonsgegevens.nl/nl/contact-met-deautoriteit-persoonsgegevens/tip-ons
            </a>
          </p>
          <h2>Hoe wij persoonsgegevens beveiligen</h2>
          <p>
            Amuse neemt de bescherming van jouw gegevens serieus en neemt
            passende maatregelen om misbruik, verlies, onbevoegde toegang,
            ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
            Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd
            zijn of er zijn aanwijzingen van misbruik, neem dan contact op met
            onze klantenservice of via remco@amusemenu.nl. Amuse heeft de
            volgende maatregelen genomen om jouw persoonsgegevens te beveiligen:
            <ul>
              <li>Beveiligingssoftware, zoals een virusscanner en firewall.</li>
              <li>
                TLS (voorheen SSL) Wij versturen jouw gegevens via een
                beveiligde internetverbinding. Dit kun je zien aan de adresbalk
                'https' en het hangslotje in de adresbalk.
              </li>
            </ul>
          </p>
        </div>
      </Content>
    </>
  );
};

export default PrivacyPolicyPage;
