import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router-dom';
import { showNotification } from '../../redux/actions/notifications.actions';
import Content from '../../components/content/content.component';
import Toolbar from '../../components/toolbar/toolbar.component';
import EmptyMessage from '../../components/ui-base/empty-message/empty-message.component';
import VisitItem from '../../components/visit-item/visit.item.component';
import HistoricSession from '../../models/history/historicSession';
import { getSessionHistory } from '../../redux/order-history/session.history.thunks';
import { AppState } from '../../redux/reducers/root.reducer';
import Bg from '../../utils/bg';
import { NotificationType } from '../../models/notification';
import NotAuthenticatedError from '../../models/errors/notAuthenticated';

type ReduxActions = {
  getSessionHistory: (token: string) => Promise<void>;
  showNotification: (
    message: string,
    type: NotificationType,
    duration?: number,
  ) => void;
};

type ReduxProps = {
  account: any;
  loggedIn: boolean;
  sessionHistory: Array<HistoricSession>;
};

type Props = {} & ReduxProps & ReduxActions & RouteChildrenProps;

class Visits extends Component<Props> {
  componentDidMount() {
    setTimeout(this.fetchSessionHistory, 20);
  }

  handleNotAuthenticated = () => {
    this.props.showNotification(
      "U bent niet ingelogd.",
      NotificationType.ERROR,
    );
    this.props.history.push('/login');
  }

  fetchSessionHistory = () => {
    const { account, loggedIn } = this.props;

    if (loggedIn) {
      this.props.getSessionHistory(account.token)
        .catch(e => {
          if (e instanceof NotAuthenticatedError) {
            this.handleNotAuthenticated();
          }
        });
    } else {
      this.handleNotAuthenticated();
    }
  };

  render() {
    const { loggedIn, sessionHistory, history } = this.props;

    if (!loggedIn) {
      return <></>;
    }

    const visitItems = sessionHistory.map((visit, index) => (
      <VisitItem
        key={index}
        visit={visit}
        onClick={() => history.push(`/visit/${visit.sessionId}`)}
      />
    ));

    return (
      <>
        <Bg />
        <Toolbar back={true} title="Uw bezoeken" />
        <Content toolbar={true}>
          <div className="pt-20" />
          {visitItems.length > 0 && visitItems}
          {visitItems.length === 0 && (
            <EmptyMessage>Geen bestellingen gevonden</EmptyMessage>
          )}
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState): ReduxProps => {
  return {
    loggedIn: state.account.loggedIn,
    account: state.account,
    sessionHistory: state.sessionHistory,
  };
};

export default connect(mapStateToProps, { getSessionHistory, showNotification })(Visits);
