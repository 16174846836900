import api2 from '../../services/api/apiv2';
import NetworkState from '../network-state';

export function getRestaurants(filters = []) {
  return async function (dispatch) {
    dispatch({
      type: 'RESTAURANTS_NETWORK_STATE',
      payload: NetworkState.LOADING,
    });
    const restaurants = await api2.restaurants.getRestaurants(filters);
    dispatch({
      type: 'RESTAURANTS_NETWORK_STATE',
      payload: NetworkState.LOADED,
    });

    if (restaurants) {
      dispatch({
        type: 'GET_RESTAURANTS',
        payload: restaurants,
      });
    } else {
      dispatch({
        type: 'GET_RESTAURANTS',
        payload: [],
      });
    }
  };
}

export function getRestaurant(slug) {
  return async function (dispatch) {
    const restaurantResponse = await api2.restaurants.getRestaurantBySlug(slug);
    if (restaurantResponse.isSuccess) {
      dispatch({
        type: 'GET_RESTAURANT',
        payload: restaurantResponse.data,
      });
    }
  };
}
