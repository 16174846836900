import {ReactComponent as QuestionIcon} from '../../assets/icons/question.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import React from 'react';
import classNames from 'classnames';
import './alt.action.style.scss';

type Props = {
  text: string;
  icon?: 'question' | 'close';
  onClick: () => void;
  className?: string;
};

const AltAction: React.FC<Props> = ({text, icon, onClick, className}) => {
  return (
    <div className={classNames('alt_action', className)} onClick={onClick}>
      <div className="text">{text}</div>
      {icon === 'question' && <QuestionIcon className="icon" />}
      {icon === 'close' && <CloseIcon width={14} height={14} fill="#7F8C8D" />}
    </div>
  );
};

export default AltAction;
