import React from 'react';
import {CSSTransition} from 'react-transition-group';
import Notification from '../../../models/notification';
import ErrorNotificationIcon from './error-icon.component';
import SuccessNotificationIcon from './success-icon.component';

import './notification.scss';

type Props = {
  notification: Notification;
};

const NotificationComponent: React.FC<Props> = (props: Props) => {
  const {notification} = props;

  const animations = {
    enter: 'enter',
    enterActive: 'enter_active',
    exit: 'exit',
    exitActive: 'exit_active',
  };

  var icon = <SuccessNotificationIcon />;
  if (notification.type === 'error') {
    icon = <ErrorNotificationIcon />;
  }

  return (
    <CSSTransition key={notification.id} timeout={500} classNames={animations}>
      <div className={`notification_item ${notification.type}`}>
        {icon}
        <p className="notification_message">{notification.message}</p>
      </div>
    </CSSTransition>
  );
};

export default NotificationComponent;
