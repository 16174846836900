import OrderReservation from '../../models/orderReservation';
import ReservationData from '../../models/reservationData';
import Table from '../../models/table';
import TableReservation from '../../models/tableReservation';
import Timeslot from '../../models/timeslot';

export default class TimeslotChecker {
  static checkTimeslotAvailabilityForOrders = (
    timeslot: Timeslot,
    orderReservations: Array<OrderReservation>,
    capacity: number,
  ): boolean => {
    const foundOrdersForTimeslot = orderReservations.filter(
      (orderReservations) =>
        orderReservations.reservationDate.hour() === timeslot.when.hour() &&
        orderReservations.reservationDate.minute() === timeslot.when.minute(),
    );
    return foundOrdersForTimeslot.length < capacity;
  };

  static checkTimeslotAvailabilityForTables = (
    timeslot: Timeslot,
    reservationData: ReservationData,
    tables: Array<Table>,
    reservations: Array<TableReservation>,
  ): boolean => {
    return (
      tables.find(
        (x) =>
          x.amountOfChairs >= reservationData.personAmount! &&
          (reservationData.location !== undefined
            ? x.location === reservationData.location
            : true) &&
          !TimeslotChecker.findReservation(reservations, x, timeslot) &&
          !TimeslotChecker.isWithinReservationBounds(reservations, x, timeslot),
      ) !== undefined
    );
  };

  /**
   * Checks if the passed timeslot has enough time available to place a reservation on the table
   * @param reservations
   * @param table
   * @param timeslot
   */
  private static isWithinReservationBounds = (
    reservations: Array<TableReservation>,
    table: Table,
    timeslot: Timeslot,
  ) => {
    const slotClone = timeslot.clone();
    slotClone.when = slotClone.when.add(table.timeslotInMinutes, 'minutes');
    const reservation = TimeslotChecker.findReservation(
      reservations,
      table,
      slotClone,
    );
    if (!reservation) {
      return false;
    } else {
      return timeslot.when.isBetween(
        reservation.from.clone().subtract(table.timeslotInMinutes, 'minutes'),
        reservation.from,
      );
    }
  };

  /**
   * Checks if a reservation exists for the given table and timeslot
   * @param reservations List of reservations
   * @param table Table to check
   * @param timeslot Timeslot to check
   *
   * @returns True when a @see TableReservation is found, otherwise false
   */
  private static isReserved = (
    reservations: Array<TableReservation>,
    table: Table,
    timeslot: Timeslot,
  ): Boolean => {
    return (
      TimeslotChecker.findReservation(reservations, table, timeslot) !==
      undefined
    );
  };

  /**
   * Attempts to find a reservation for the given table for the given timeslot
   * @param reservations List of reservations
   * @param table Table to check
   * @param timeslot Timeslot to check
   *
   * @returns Found @see TableReservation or undefined
   */
  private static findReservation = (
    reservations: Array<TableReservation>,
    table: Table,
    timeslot: Timeslot,
  ): TableReservation | undefined => {
    return reservations.find(
      (reservation) =>
        reservation.tableNumber === table.tableNumber &&
        timeslot.when.isSameOrAfter(reservation.from) &&
        timeslot.when.isSameOrBefore(reservation.to),
    );
  };
}
