import * as React from 'react';
import './input-group.styles.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  invalid?: boolean;
};

const InputGroup: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={`input-group ${
        props.invalid !== undefined && props.invalid ? 'invalid' : ''
      } ${props.className}`}>
      {props.children}
    </div>
  );
};
InputGroup.defaultProps = {
  className: '',
};
export default InputGroup;
