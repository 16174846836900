import {useEffect} from 'react';
import {createPortal} from 'react-dom';

type Props = {
  children: React.ReactNode;
};

const NotificationsPortal: React.FC<Props> = ({children}: Props) => {
  const mount = document.getElementById('notifications-root') as HTMLDivElement;
  const el = document.createElement('div');

  useEffect(() => {
    mount.appendChild(el);
    return () => {
      mount.removeChild(el);
    };
  }, [el, mount]);

  return createPortal(children, el);
};

export default NotificationsPortal;
