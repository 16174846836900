import React from 'react';
import './button.scss';

export type ButtonProps = {
  type: Type;
  link?: string;
  className?: string;
  onClick?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  iconClass?: string;
  fontSize?: string;
};

type Type = 'primary' | 'secondary' | 'tertiary';

const Button: React.FC<ButtonProps> = ({
  children,
  type,
  link,
  className = '',
  onClick,
  icon: Icon,
  iconClass = '',
}) => {
  const innerButton = (
    <div className={`${type} ${className} inner-button`} onClick={onClick}>
      <p> {children}</p>
      {Icon && <Icon className={`arrow ${iconClass}`} />}
    </div>
  );

  if (link) {
    return <a href={link}>{innerButton}</a>;
  }
  return innerButton;
};

export default Button;
