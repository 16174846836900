import DrawerMenuItem from '../../components/drawer-menu-item/drawer.menu.item.component';
import ImageHeader from '../../components/image-header/image-header.component';
import {ReactComponent as QuestionMarkIcon} from '../../assets/icons/question_mark.svg';
import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {ReactComponent as ListIcon} from '../../assets/icons/list.svg';
import {ReactComponent as ScanIcon} from '../../assets/icons/scan.svg';
import {ReactComponent as LocationIcon} from '../../assets/icons/location-menuitem.svg';
import {ReactComponent as SecurityIcon} from '../../assets/icons/security.svg';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import Content from '../../components/content/content.component';
import {hideMenu} from '../../redux/actions/menu.actions';
import header from '../../assets/images/menu_header.png';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import './drawer-menu.style.scss';
import BusinessLink from '../../components/business-link/business-link';
import {isMobileOrTablet} from '../../utils/isMobileOrTablet';
import {AppState} from '../../redux/reducers/root.reducer';

type DrawerMenuEntry = {
  name: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  link: string;
  iconWidth?: number;
  iconHeight?: number;
};

type ReduxProps = {
  visible: boolean;
  loggedIn: boolean;
};

type ReduxActions = {
  hideMenu: () => void;
};

type Props = {} & ReduxProps & ReduxActions & RouteComponentProps;

class DrawerMenu extends Component<Props> {
  items: Array<DrawerMenuEntry>;

  constructor(props: Props) {
    super(props);

    if (isMobileOrTablet()) {
      this.items = [
        {name: 'Uitleg', icon: QuestionMarkIcon, link: '/walkthrough'},
        {name: 'QR-code scannen', icon: ScanIcon, link: '/scanner'},
        {name: 'Restaurant overzicht', icon: ListIcon, link: '/restaurants'},
        {name: 'Kaart', icon: LocationIcon, link: '/restaurants-map'},
        {name: 'Drukte overzicht', icon: ListIcon, link: '/occupations'},
        {name: 'Privacy beleid', icon: SecurityIcon, link: '/privacy-policy'},
      ];
    } else {
      this.items = [
        {name: 'Uitleg', icon: QuestionMarkIcon, link: '/walkthrough'},
        {name: 'Restaurant overzicht', icon: ListIcon, link: '/restaurants'},
        {name: 'Kaart', icon: LocationIcon, link: '/restaurants-map'},
        {name: 'Drukte overzicht', icon: ListIcon, link: '/occupations'},
        {name: 'Privacy beleid', icon: SecurityIcon, link: '/privacy-policy'},
      ];
    }
  }

  _navigate = (link: string) => {
    this.props.hideMenu();
    this.props.history.push(link);
  };

  _renderMenu = () => {
    const animations = {
      enter: 'enter',
      enterActive: 'enter_active',
      exit: 'exit',
      exitActive: 'exit_active',
    };

    const {hideMenu, loggedIn} = this.props;

    const items = [...this.items];
    if (loggedIn) {
      items.push({name: 'Profiel', icon: ProfileIcon, link: '/profile'});
      items.push({
        name: 'Uitloggen',
        icon: CloseIcon,
        link: '/logout',
        iconWidth: 20,
        iconHeight: 20,
      });
    } else {
      items.push({name: 'Inloggen', icon: ProfileIcon, link: '/login'});
    }

    return (
      <CSSTransition timeout={300} classNames={animations}>
        <div className="drawer_menu_container">
          <div className="drawer_menu">
            <Content className="bg-broken-white">
              <ImageHeader header={header} ratio="64" />

              <div className="drawer_menu_content">
                <button className="drawer_menu_close_button" onClick={hideMenu}>
                  <CloseIcon
                    className="drawer_menu_close_icon"
                    fill="#183F57"
                  />
                </button>

                <div className="center mt-30" style={{width: 140}}>
                  <Logo />
                </div>

                <div className="mt-40" />

                <div className="flex flex-column">
                  {items.map((item, index) => (
                    <DrawerMenuItem
                      key={index}
                      item={item}
                      onClick={() => item && this._navigate(item.link)}
                    />
                  ))}
                  <BusinessLink />
                </div>
              </div>
            </Content>
          </div>
        </div>
      </CSSTransition>
    );
  };

  render() {
    const {visible} = this.props;

    return <TransitionGroup>{visible && this._renderMenu()}</TransitionGroup>;
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    visible: state.menu.visible,
    loggedIn: state.account.loggedIn,
  };
};

export default connect(mapStateToProps, {hideMenu})(withRouter(DrawerMenu));
