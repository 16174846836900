export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export default class Notification {
  /**
   * Identifier of this notification, generated upon construction
   */
  id: string;
  /**
   * How long this notification should be shown
   */
  duration: number;
  /**
   * Message this notification should display
   */
  message: string;
  /**
   * What kind of notification this is
   */
  type: NotificationType;

  constructor(message: string, type: NotificationType, duration: number) {
    this.id = `${new Date().getTime()}${Math.random() * 1337}`;
    this.duration = duration;
    this.message = message;
    this.type = type;
  }
}
