import React from 'react';
import './dot.indicator.style.scss';

type Props = {
    dots: number;
    activeIndex: number;
};

const DotIndicator: React.FC<Props> = ({ dots, activeIndex }) => {
    const dotItems = [];
    for (let i = 0; i < dots; i++) {
        dotItems.push(<div className={i === activeIndex ? 'dot active' : 'dot'} key={i} />);
    }
    return (
        <div className="dot_indicator">
            {dotItems}
        </div>
    );
}

export default DotIndicator;