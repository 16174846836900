import RestaurantLabel from '../../models/restaurantLabel';
import imageUrl from '../../utils/scalar';
import './category-item.scss';

type Props = {
  toggleCategory: (category: RestaurantLabel) => void;
  category: RestaurantLabel;
};

const CategoryItem: React.FC<Props> = ({toggleCategory, category}) => {
  return (
    <div
      onClick={() => toggleCategory(category)}
      className="category"
      style={{backgroundImage: `url(${imageUrl(category.image)})`}}>
      <span className="name">{category.title}</span>
    </div>
  );
};

export default CategoryItem;
