import NotAuthenticatedError from '../../models/errors/notAuthenticated';
import api from '../../services/api';
import {AppThunk} from '../reducers/root.reducer';
import {SET_HISTORY} from './order-history.actiontypes';

export const getSessionHistory =
  (token: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const response = await api.getOrderHistory(token);

    if (response) {
      dispatch({
        type: SET_HISTORY,
        payload: response,
      });
    } else {
      throw new NotAuthenticatedError();
    }
  };
