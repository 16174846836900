import React from 'react';
import {Link} from 'react-router-dom';
import './restaurants-quickaction.styles.scss';

type Props = {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  text: string;
  link?: string;
  onClick?: () => void;
};

const RestaurantsQuickAction: React.FC<Props> = (props: Props) => {
  const Icon = props.icon;

  if (props.link) {
    return (
      <Link to={props.link} className="quick-action">
        <Icon className="icon" />
        <h1>{props.text}</h1>
      </Link>
    );
  } else
    return (
      <div className="quick-action" onClick={props.onClick}>
        <Icon className="icon" />
        <h1>{props.text}</h1>
      </div>
    );
};

export default RestaurantsQuickAction;
