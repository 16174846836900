import Logger from '../../../utils/logger';
import {NotificationType} from '../../../models/notification';
import {showNotification} from '../../../redux/actions/notifications.actions';
import APIResponse from '../../../services/api/responses/APIResponse';
import MakeReservationResponse from '../../../services/api/responses/makeReservationResponse';
import {ReservationData} from './reservation-interfaces';

export const reservationResponseHelper = (
  reservationData: ReservationData,
  response: APIResponse<MakeReservationResponse> | null,
  dispatch: (func: any) => void,
) => {
  if (response) {
    switch (response.error) {
      case 'restaurantClosed':
        dispatch(
          showNotification(
            'Het restaurant is niet open op de opgegeven tijd/datum',
            NotificationType.ERROR,
          ),
        );
        break;
      case 'invalidName':
        dispatch(showNotification('Geef een naam op', NotificationType.ERROR));
        // reservationData.invalidField = 'fullName';
        break;
      case 'invalidPhoneNumber':
        dispatch(
          showNotification(
            'Geef een (valide) telefoonnummer op.',
            NotificationType.ERROR,
          ),
        );
        // reservationData.invalidField = 'phone';
        break;
      case 'invalidEmail':
        dispatch(
          showNotification(
            'Geef een (valide) e-mailadres op.',
            NotificationType.ERROR,
          ),
        );
        // reservationData.invalidField = 'email';
        break;
      case 'invalidDatetime':
        dispatch(
          showNotification(
            'De opgegeven datum & tijd combinatie is niet correct.',
            NotificationType.ERROR,
          ),
        );
        // reservationData.invalidField = 'day';
        break;
      case 'invalidType':
        showNotification('Geef een locatie op', NotificationType.ERROR);
        // reservationData.invalidField = 'location';
        break;
      case 'noTablesAvailable':
        dispatch(
          showNotification(
            'Geen tafels beschikbaar voor dit moment op de gevraagde locatie voor dit aantal personen',
            NotificationType.ERROR,
          ),
        );
        // reservationData.invalidField = 'location';
        break;
      default:
        dispatch(
          showNotification(
            'Fout bij maken reservering.' + response.error,
            NotificationType.ERROR,
          ),
        );
    }
    Logger.log(reservationData);
  } else {
    dispatch(
      showNotification('Fout bij maken reservering.', NotificationType.ERROR),
    );
  }
};
