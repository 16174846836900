export default class RestaurantLabel {
  title: string;
  label: string;
  image: string;
  highlighted: string;

  constructor(json: any) {
    this.title = json.title;
    this.label = json.label;
    this.image = json.image;
    this.highlighted = json.highlighted;
  }
}
