import {ReactComponent as CheckIcon} from '../../../assets/icons/check.svg';
import React, {ReactNode} from 'react';
import './checkbox.style.scss';

type Props = {
  className?: string;
  checked?: boolean;
  onChecked?: (isChecked: boolean) => void;
  text?: string;
  textComponent?: ReactNode;
  valid?: boolean;
};

const Checkbox: React.FC<Props> = (props: Props) => {
  const checkboxClass = props.checked ? 'checkbox selected' : 'checkbox';
  return (
    <div
      className={`checkbox_wrapper ${props.valid ? '' : 'invalid'} ${
        props.className
      }`}
      onClick={() => {
        if (props.onChecked) props.onChecked(!props.checked);
      }}>
      <div className={checkboxClass}>
        {props.checked && (
          <CheckIcon className="checkbox_check_icon" fill="white" />
        )}
      </div>
      <div className="checkbox_text">
        {props.textComponent ? props.textComponent : props.text}
      </div>
    </div>
  );
};

Checkbox.defaultProps = {
  className: '',
  valid: true,
};

export default Checkbox;
