import React from 'react';
import './modal-dialog-title.styles.scss';

type Props = {
  children: string;
  className?: string;
};

const ModalDialogTitle: React.FC<Props> = (props: Props) => {
  const defaultClassName = 'modal-dialog-title';
  const customClasses = props.className!.split(' ');
  const classNames = [defaultClassName, ...customClasses].join(' ');
  return <h1 className={classNames}>{props.children}</h1>;
};

ModalDialogTitle.defaultProps = {
  className: '',
};

export default ModalDialogTitle;
