import React, {useEffect, useState} from 'react';
import {ReactComponent as LikedIcon} from '../../assets/icons/liked.svg';
import {ReactComponent as LikeIcon} from '../../assets/icons/like.svg';
import classify from '../../utils/classify';

import './favorite-icon-button.styles.scss';

type Props = {
  className?: string;
  favorited: boolean;
  onClick: () => void;
};

export const FavoriteIconButton: React.FC<Props> = (props: Props) => {
  const FavoriteIcon = props.favorited ? LikedIcon : LikeIcon;
  const [className, setClassName] = useState<string>(
    classify('btn_favorite', props),
  );
  useEffect(() => {
    setClassName(classify('btn_favorite', props));
  }, [props]);
  return (
    <FavoriteIcon
      className={className}
      onClick={(event) => {
        event.stopPropagation();
        props.onClick();
      }}
    />
  );
};
