import React, {Component} from 'react';
import './floating.button.style.scss';

type Props = {
    className?: string;
    onClick: () => void;
    number?: number;
}

export default class FloatingButton extends Component<Props> {
    render() {
        const {className, onClick, number} = this.props;
        return (
            <button className={"floating-button " + (className ? className : '')} onClick={onClick}>
                <div className="circle">
                    {this.props.children}
                    {!!(number && number > 0) && <div className="number">{number}</div>}
                </div>
            </button>
        );
    }
}
