import './radio-button.scss';
import React, {useCallback} from 'react';

type Props = {
  onSelect: (selected: boolean) => void;
  isSelected: boolean;
};

const RadioButton: React.FC<Props> = ({onSelect, isSelected}) => {
  const onClick = useCallback(() => {
    onSelect(!isSelected);
  }, [isSelected, onSelect]);

  return (
    <div
      onClick={onClick}
      className={`radio-button ${isSelected ? 'selected' : ''}`}>
      <div className="inner-radio-button"></div>
    </div>
  );
};

export default RadioButton;
