import {useState} from 'react';
import {ReactComponent as ChevronUp} from '../../../../assets/icons/chevron-up.svg';
import {ReactComponent as ChevronDown} from '../../../../assets/icons/chevron-down.svg';
import {ReactComponent as ChevronRight} from '../../../../assets/icons/chevron-right.svg';
import Card from '../../components/card/card';
import './collapsable-card.scss';
import Button from '../../components/button/button.component';

type Props = {
  icon: any;
  title: string;
  text: string;
};

const CollapsableCard: React.FC<Props> = ({icon: Icon, text, title}) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div className="collapsable-card">
      <Card
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        className="card">
        <div className="flex">
          <Icon className="icon" />
        </div>
        <div className="content">
          <h3>{title}</h3>
          {!collapsed && <p className="text">{text}</p>}
        </div>
        <div className="collapsable-card">
          {collapsed ? <ChevronDown /> : <ChevronUp />}
        </div>
      </Card>
    </div>
  );
};

export default CollapsableCard;
