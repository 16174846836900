import OccupationGroup from "../../models/occupationGroup";
import RestaurantOccupation from "../../models/restaurantOccupation";

export const OCCUPATION_GROUPS_RETRIEVED = 'OCCUPATION_GROUPS_RETRIEVED';

export type OccupationGroupsRetrievedAction = {
    type: typeof OCCUPATION_GROUPS_RETRIEVED,
    payload: {
        groups: Array<OccupationGroup>,
        ungroupedRestaurants: Array<RestaurantOccupation>
    }
}

export type OccupationActions = OccupationGroupsRetrievedAction;