import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ReactComponent as LocationIcon} from '../../assets/icons/location-pin-orange.svg';
import './map-button.styles.scss';

type Props = {} & RouteComponentProps;

const MapButton: React.FC<Props> = ({history}) => {
  const openMap = () => {
    history.push('/restaurants-map');
  };

  return (
    <div className="map-button" onClick={openMap}>
      <LocationIcon></LocationIcon>
      <span>Kaartweergave</span>
    </div>
  );
};

export default withRouter(MapButton);
