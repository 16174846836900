import React, {ReactNode} from 'react';
import classify from '../../../utils/classify';
import './modal-dialog-actions.styles.scss';

export enum ActionsOrientation {
  Vertical,
  Horizontal,
}

type Props = {
  children: ReactNode;
  className?: string;
  orientation?: ActionsOrientation;
};

const ModalDialogActions: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={classify(
        `actions ${
          props.orientation === ActionsOrientation.Horizontal
            ? 'horizontal'
            : 'vertical'
        }`,
        props,
      )}>
      {props.children}
    </div>
  );
};

ModalDialogActions.defaultProps = {
  orientation: ActionsOrientation.Horizontal,
};

export default ModalDialogActions;
