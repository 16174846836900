import './filters.container.style.scss';
import React, {Component} from 'react';
import Filter from '../../redux/search/filter';
import ActiveFilterView from './active-filter/active-filter.component';

type Props = {
  filters: Array<Filter>;
};

export default class FiltersContainer extends Component<Props> {
  _renderFilter = (filter: Filter, index: number) => {
    return <ActiveFilterView key={index} filter={filter} />;
  };

  render() {
    const {filters} = this.props;

    const items = filters.map((filter, index) =>
      this._renderFilter(filter, index),
    );

    return <div className="filters_container">{items}</div>;
  }
}
