// Classes used by Leaflet to position controls
import React from 'react';
import LegendEntry from './legend-entry.component';
import './legend.scss';

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

const Legend: React.FC = () => {
  const position = undefined;
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.bottomleft;
  return (
    <div className={positionClass}>
      <div className="leaflet-control leaflet-bar legend">
        <p className="title">Drukte</p>
        <LegendEntry color="#27ae60" label="Rustig" />
        <LegendEntry color="#E7AC5B" label="Gemiddeld" />
        <LegendEntry color="#e74c3c" label="Druk" />
      </div>
    </div>
  );
};

export default Legend;
