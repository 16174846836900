import React from 'react';
import {
  ModalDialogDescription,
  ModalDialogTitle,
  ModalDialog,
} from '../../components/ui-base';
import './yes.no.dialog.style.scss';

type Props = {
  title: string;
  description: string;
  onComplete: () => void;
  onCancel: () => void;
};

const YesNoDialog: React.FC<Props> = (props: Props) => {
  const {title, description, onComplete, onCancel} = props;

  return (
    <ModalDialog>
      <ModalDialogTitle className="yes_no_dialog_title">
        {title}
      </ModalDialogTitle>
      <ModalDialogDescription>{description}</ModalDialogDescription>

      <div className="yes_no_dialog_options">
        <div className="flex-1" />
        <button className="yes_no_dialog_button highlight" onClick={onComplete}>
          Ja
        </button>
        <button className="yes_no_dialog_button" onClick={onCancel}>
          Nee
        </button>
      </div>
    </ModalDialog>
  );
};

export default YesNoDialog;
