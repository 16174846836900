import React from 'react';
import './card-title.scss';

type Props = {
  children?: string;
};

const CardTitle: React.FC<Props> = (props: Props) => {
  return <h1 className="card-title">{props.children}</h1>;
};

export default CardTitle;
