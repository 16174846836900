export function hideMenu() {
    document.body.parentElement.style.overflow = 'auto';
    return async function (dispatch) {
        dispatch({
            type: 'HIDE_MENU',
            payload: {}
        });
    }
}

export function showMenu() {
    document.body.parentElement.style.overflow = 'hidden';
    return async function (dispatch) {
        dispatch({
            type: 'SHOW_MENU',
            payload: {}
        });
    }
}
