const initialState = {visible: false};

const MenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_MENU':
            return {...state, visible: true};
        case 'HIDE_MENU':
            return {...state, visible: false};
        default:
            return state;
    }
};

export default MenuReducer;

