import React from 'react';
import Checkbox from '../checkbox/checkbox.component';
import InputGroup from '../input-group/input-group.component';
import Label from '../label/label.component';
import './check-group.scss';

export type CheckboxOption<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  options: Array<CheckboxOption<T>>;
  selectedOptions: Array<CheckboxOption<T>>;
  onSelectionChange: (newSelection: Array<CheckboxOption<T>>) => void;
  valid?: boolean;
};

const CheckGroup = <T extends any>(props: Props<T>) => {
  const onOptionPressed = (selectedOption: CheckboxOption<T>) => {
    if (props.selectedOptions.find((x) => selectedOption.label === x.label)) {
      props.onSelectionChange(
        props.selectedOptions.filter((x) => selectedOption.label !== x.label),
      );
    } else {
      props.onSelectionChange([...props.selectedOptions, selectedOption]);
    }
  };

  const {options, selectedOptions} = props;
  return (
    <>
      {options.map((option) => (
        <Checkbox
          key={'checkbox_' + option.label}
          text={option.label}
          checked={selectedOptions.some((x) => x.label === option.label)}
          onChecked={() => onOptionPressed(option)}
        />
      ))}
    </>
  );
};

export default CheckGroup;
