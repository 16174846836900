import OccupationGroup from "../../models/occupationGroup";
import { OCCUPATION_GROUPS_RETRIEVED, OccupationActions } from "./occupation.actiontypes";
import RestaurantOccupation from "../../models/restaurantOccupation";

export const occupationGroupsRetrieved = (groups: Array<OccupationGroup>, ungroupedRestaurants: Array<RestaurantOccupation>): OccupationActions => ({
    type: OCCUPATION_GROUPS_RETRIEVED,
    payload: {
        groups,
        ungroupedRestaurants
    }
});
