import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../../redux/reducers/root.reducer';
import RestaurantOccupation from '../../../models/restaurantOccupation';
import {ReactComponent as LocationIcon} from '../../../assets/icons/location.svg';
import placeholder_small from '../../../assets/images/placeholder_small.png';
import './restaurant.styles.scss';
import imageUrl from '../../../utils/scalar';
import classify from '../../../utils/classify';
import Restaurant from '../../../models/restaurant';

type InputProps = {
  className?: string;
  occupation: RestaurantOccupation;
  onPress: () => void;
};

type ReduxProps = {
  restaurant: Restaurant | undefined;
};

export class RestaurantOccupationView extends Component<
  InputProps & ReduxProps
> {
  _getImage = () => {
    const {restaurant} = this.props;

    if (
      restaurant &&
      restaurant.profileImage &&
      restaurant.profileImage.length > 0
    ) {
      return imageUrl(restaurant.profileImage);
    }

    return placeholder_small;
  };

  render() {
    const {restaurant, occupation, onPress} = this.props;
    if (!restaurant) {
      return null;
    }

    const location = `${restaurant.street} ${restaurant.streetNumber}, ${restaurant.city}`;
    const description = Object.keys(restaurant.labels)
      .map((key) => restaurant.labels[key])
      .join(', ')
      .split(',')
      .join(', ');
    let indicatorStyle = '';
    if (occupation.occupationPercentage > 80) {
      indicatorStyle = 'high';
    } else if (occupation.occupationPercentage > 60) {
      indicatorStyle = 'medium';
    }

    return (
      <div
        className={classify('restaurant-occupation', this.props)}
        onClick={onPress}>
        <img
          src={this._getImage()}
          className="restaurant_item_image"
          alt="restaurant"
        />
        <div className="restaurant_item_info">
          <h1 className="restaurant_item_title">{restaurant.title}</h1>
          <h2 className="restaurant_item_description">{description}</h2>
          <span className="restaurant_item_location">
            <LocationIcon className="restaurant_item_location_icon" />{' '}
            {location}
          </span>
        </div>
        <span
          className={
            'occupation-indicator ' + indicatorStyle
          }>{`${occupation.occupationPercentage}%`}</span>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: InputProps): ReduxProps => {
  return {
    restaurant: state.restaurants.restaurants.find(
      (x) => x.id === props.occupation.id,
    ),
  };
};

export default connect(mapStateToProps, {})(RestaurantOccupationView);
