import {applyMiddleware, createStore} from 'redux';
import RootReducer from '../reducers/root.reducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const middleware = [thunk];
const composeEnhancers = composeWithDevTools;
const enhancer = composeEnhancers(applyMiddleware(...middleware));

export const store = createStore(RootReducer, {}, enhancer);
