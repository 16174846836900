import './map.screen.scss';

import L, {ErrorEvent} from 'leaflet';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {StaticContext} from 'react-router';
import {RouteComponentProps} from 'react-router-dom';

import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg';
import {ReactComponent as ScanIcon} from '../../assets/icons/scan.svg';
import Content from '../../components/content/content.component';
import RestaurantsMap, {
  LOCATION_ERROR_PERMISSION_DENIED,
} from '../../components/restaurants-map/restaurants-map';
import ToolbarComponent from '../../components/toolbar/toolbar.component';
import {AppState} from '../../redux/reducers/root.reducer';
import {isMobileOrTablet} from '../../utils/isMobileOrTablet';
import {GPSButton} from './gps-button';
import {GPSState} from './gps-state';

type LocationState = {
  gpsState: GPSState;
  center?: L.LatLng;
  zoom?: number;
};

type ReduxProps = {
  account: any;
};

type Props = ReduxProps & RouteComponentProps<{}, StaticContext, LocationState>;

type State = {
  gpsState: GPSState;
  center: L.LatLng;
  zoom: number;
};

export class MapScreen extends Component<Props, State> {
  menuItems = [
    {
      id: 'profile',
      icon: ProfileIcon,
    },
    {
      id: 'scan',
      icon: ScanIcon,
      className: isMobileOrTablet() ? '' : 'dont-show-on-desktop',
    },
  ];

  constructor(props: Props) {
    super(props);
    let state: State = {
      gpsState: GPSState.FIXED,
      center: new L.LatLng(51.557153, 5.090697),
      zoom: 15,
    };
    if (props.location.state?.center) {
      state.center = props.location.state?.center!;
    }
    if (props.location.state?.zoom) {
      state.zoom = props.location.state?.zoom!;
    }
    this.state = state;
  }

  onLocationError = (locationError: ErrorEvent) => {
    if (locationError.code === LOCATION_ERROR_PERMISSION_DENIED) {
      this.setState({gpsState: GPSState.UNAVAILABLE});
    } else {
      this.setState({gpsState: GPSState.INACTIVE});
    }
  };

  onFixGPSClicked = () => {
    this.setState({
      gpsState: GPSState.FIXED,
    });
  };

  onMenuItemClicked = (item: any) => {
    const {account} = this.props;
    if (item.id === 'scan') {
      this.props.history.push('/scanner');
    } else if (item.id === 'profile') {
      if (account.loggedIn) {
        this.props.history.push('/profile');
      } else {
        this.props.history.push('/login');
      }
    }
  };

  onMapDragged = () => {
    const {gpsState} = this.state;
    this.setState({
      gpsState:
        gpsState === GPSState.UNAVAILABLE
          ? GPSState.UNAVAILABLE
          : GPSState.INACTIVE,
    });
  };

  render() {
    const {gpsState, center, zoom} = this.state;
    return (
      <>
        <ToolbarComponent
          title="Restaurants"
          showMenuIcon={true}
          items={this.menuItems}
          onItemClicked={this.onMenuItemClicked}
        />
        <Content toolbar={true}>
          <div className="map-container">
            <RestaurantsMap
              onDrag={this.onMapDragged}
              onLocationError={this.onLocationError}
              initialCenter={center}
              initialZoom={zoom}
              gpsState={gpsState}
            />
          </div>
          <GPSButton gpsState={gpsState} onClick={this.onFixGPSClicked} />
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps, {})(MapScreen);
