import './step0.scss';
import Waiter from '../../../../../../assets//images/waiter.png';
import {Typography} from '../../../../../../components/ui-base';

const Step0: React.FC = () => {
  return (
    <div className="step0">
      <img src={Waiter} alt="ober" />
      <div className="text-bubble">
        <Typography className="header4">
          Wat leuk dat je een tafeltje bij ons wilt reserveren!
        </Typography>
        <Typography className="copy">
          En dat is zo gebeurd. Beantwoord eenvoudig de volgende paar vraagjes
          en wij doen de rest.
        </Typography>
      </div>
    </div>
  );
};

export default Step0;
