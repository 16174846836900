import React, {Component} from 'react';
import './price-range.styles.scss';

type Props = {
  value?: number;
  onlyRenderSelected?: boolean;
  onSelect?: (selectedIndex: number) => void;
};

class PriceRange extends Component<Props> {
  state = {
    selected: 0,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      selected: props.value ? props.value : 0,
    };
  }

  _handleClick = (clickedIndex: number) => {
    const {onSelect} = this.props;
    if (onSelect) {
      onSelect(clickedIndex);
      this.setState({selected: clickedIndex});
    }
  };

  _renderIcons = () => {
    const {onlyRenderSelected} = this.props;
    const {selected} = this.state;
    const items = [];

    for (let i = 0; i < 5; i++) {
      if (i < selected) {
        items.push(
          <span
            key={i}
            className="selected"
            onClick={() => this._handleClick(i + 1)}>
            €
          </span>,
        );
      } else if (!onlyRenderSelected) {
        items.push(
          <span key={i} onClick={() => this._handleClick(i + 1)}>
            €
          </span>,
        );
      }
    }

    return items;
  };

  render() {
    const {onSelect} = this.props;
    return (
      <div className={`price_range_container ${onSelect ? 'selectable' : ''}`}>
        {this._renderIcons()}
      </div>
    );
  }
}

export default PriceRange;
