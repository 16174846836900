import SessionAPI from './session.api';
import OccupationAPI from './occupation.api';
import ReservationsAPI from './reservations.api';
import ProfileAPI from './profile.api';
import OrdersAPI from './orders.api';
import MenuAPI from './menu.api';
import RestaurantsAPI from './restaurants.api';
import BlogsAPI from './blogs.api';
import FaqsAPI from './faq.api';

class API {
  occupation: OccupationAPI = new OccupationAPI();
  menu: MenuAPI = new MenuAPI();
  orders: OrdersAPI = new OrdersAPI();
  profile: ProfileAPI = new ProfileAPI();
  reservations: ReservationsAPI = new ReservationsAPI();
  restaurants: RestaurantsAPI = new RestaurantsAPI();
  session: SessionAPI = new SessionAPI();
  blogs: BlogsAPI = new BlogsAPI();
  faq: FaqsAPI = new FaqsAPI();
}

const instance = new API();

export default instance;
