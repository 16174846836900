import AspectRatioContainer from '../aspect-ratio-container/aspect-ratio-container.component';
import React from 'react';

type Props = {
    ratio : string;
    header : string;
}

const ImageHeader : React.FC<Props> = (props: Props) => {
    return (
        <AspectRatioContainer ratio={props.ratio}>
            <img src={props.header} alt="header" width="100%" height="100%"/>
        </AspectRatioContainer>
    );
};

export default ImageHeader;