import {
  SessionActions,
  SET_SESSION,
  RESTORE_SESSION,
  REMOVE_SESSION,
  UPDATE_SESSION_DATA,
  SET_SESSION_CHANGE_TOKEN,
  UPDATE_SESSION_STATUS,
  UPDATE_USER_GREETING_STATE,
} from './session.actiontypes';
import Session from '../../models/session';
import {SessionJSON} from '../../models/json/session.json';

export const setSession = (session: Session): SessionActions => ({
  type: SET_SESSION,
  payload: session,
});

export const restoreSessionAction = (): SessionActions => ({
  type: RESTORE_SESSION,
});

export const removeSessionAction = (): SessionActions => ({
  type: REMOVE_SESSION,
});

export const updateSessionDataAction = (
  update: SessionJSON,
): SessionActions => ({
  type: UPDATE_SESSION_DATA,
  payload: update,
});

export const setSessionChangeTokenAction = (token: string): SessionActions => ({
  type: SET_SESSION_CHANGE_TOKEN,
  payload: token,
});

export const updateSessionStatusAction = (
  newStatus: string,
): SessionActions => ({
  type: UPDATE_SESSION_STATUS,
  payload: newStatus,
});

export const updateUserGreetingState = (
  hasUserBeenGreeted: boolean,
): SessionActions => ({
  type: UPDATE_USER_GREETING_STATE,
  payload: hasUserBeenGreeted,
});
