const initialState = {};

const RestaurantMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MENU':
            const restaurantId = action.payload.id;
            const newState = {...state};
            newState[restaurantId] = [...action.payload.items];
            return newState;
        default:
            return state;
    }
};

export default RestaurantMenuReducer;
