import classify from '../../../utils/classify';
import React, {PropsWithChildren} from 'react';

import './label.scss';

type Props = {
  className?: string;
};

const Label: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>,
) => {
  return <label className={classify('', props)}>{props.children}</label>;
};
export default Label;
