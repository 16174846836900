import moment from 'moment';
import React from 'react';
import { InputGroup, Label, Input } from '../../../components/ui-base';

type Props = {
  deliveryTime: moment.Moment,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>,
};

const DateSelect: React.FC<Props> = (props) => {
  const now = moment();
  const maxDate = moment(now).add(1, 'month');
  const value = props.deliveryTime.format('YYYY-MM-DD');
  return (
    <InputGroup>
      <Label>Datum</Label>
      <Input
        type="date"
        value={value}
        min={now.format('YYYY-MM-DD')}
        max={maxDate.format('YYYY-MM-DD')}
        onChange={props.onChange}
      />
    </InputGroup>
  );
}

export default DateSelect;