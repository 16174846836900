import HistoricSession from '../../models/history/historicSession';

export const SET_HISTORY = 'SET_HISTORY';

export type SetHistoryAction = {
  type: typeof SET_HISTORY;
  payload: Array<HistoricSession>;
};

export type OrderHistoryActions = SetHistoryAction;
