import {Typography} from '../../../../../components/ui-base';
import './reservation-header.scss';
import {ReactComponent as Close} from '../../../../../assets/icons/close.svg';

type Props = {
  step: number;
  onClose: () => void;
};

const ReservationHeader: React.FC<Props> = ({step, onClose}) => {
  const stepString = (step: number) => {
    if (step <= 0) {
      return 'Direct online';
    }
    if (step >= 6) {
      return 'Voltooid';
    }
    return `Stap ${step}/5`;
  };

  return (
    <div className="reservation-header">
      <div className="row">
        <div className="copy">
          <Typography type="h6" headerClass="header6">
            Reserveren
          </Typography>
          <div className="divider" />
          <Typography className="step" type="span">
            {stepString(step)}
          </Typography>
        </div>
        <Close className="close" onClick={onClose} />
      </div>
    </div>
  );
};

export default ReservationHeader;
