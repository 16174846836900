import {ChangeEvent, useState} from 'react';
import {
  Input,
  InputGroup,
  Label,
  Typography,
} from '../../../../../../components/ui-base';
import {CustomerDetails} from '../../../reservation-interfaces';
import './step5.scss';

type Props = {
  customerDetails: CustomerDetails;
  setCustomerDetails: (customer: CustomerDetails) => void;
};

enum CustomerDetailsFields {
  Email = 'email',
  Name = 'name',
  PhoneNumber = 'phoneNumber',
}

const Step5: React.FC<Props> = ({customerDetails, setCustomerDetails}) => {
  const [error, setError] = useState<string>();

  const runValidation = (type: string, value: string) => {
    switch (type) {
      case CustomerDetailsFields.Email:
        const emailRegex = RegExp(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
        );
        return emailRegex.test(value);
      case CustomerDetailsFields.PhoneNumber:
        const phoneNumberRegex = RegExp(
          /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/,
        );
        return phoneNumberRegex.test(value);
      default:
        const nameRegex = RegExp(/^(?! )[A-Za-z\s]+$/);
        return nameRegex.test(value);
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const {name, value} = e.target;
    if (!runValidation(name, value)) setError(name);
    setCustomerDetails({...customerDetails, [name]: value});
  };

  return (
    <div className="step5">
      <InputGroup
        className="reservation-input-container"
        invalid={error === CustomerDetailsFields.Name}>
        <Label className="reservation-input-label">Naam</Label>
        <Input
          className="reservation-input"
          placeholder="Vul uw volledige naam in"
          name={CustomerDetailsFields.Name}
          value={customerDetails.name}
          onChange={onChangeHandler}
          noUnderline
        />
      </InputGroup>
      <InputGroup
        className="reservation-input-container"
        invalid={error === CustomerDetailsFields.Email}>
        <Label className="reservation-input-label">E-mailadres</Label>
        <Input
          className="reservation-input"
          type={CustomerDetailsFields.Email}
          name={CustomerDetailsFields.Email}
          placeholder="Vul uw e-mailadres in"
          value={customerDetails.email}
          onChange={onChangeHandler}
          noUnderline
        />
      </InputGroup>
      <InputGroup
        className="reservation-input-container"
        invalid={error === CustomerDetailsFields.PhoneNumber}>
        <Label className="reservation-input-label">Telefoon</Label>
        <Input
          className="reservation-input"
          type={CustomerDetailsFields.PhoneNumber}
          name={CustomerDetailsFields.PhoneNumber}
          placeholder="Vul uw telefoonnummer in"
          value={customerDetails.phoneNumber}
          onChange={onChangeHandler}
          noUnderline
        />
      </InputGroup>
      <Typography className="extra">
        Tip: maak een account aan op Amuse en je kunt deze stap voortaan
        overslaan.
      </Typography>
    </div>
  );
};

export default Step5;
