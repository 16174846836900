import CategoryHeader from '../../../../components/category-header/category.header.component';
import imageUrl from '../../../../utils/scalar';

import './menu-category-header.styles.scss';

type Props = {
  category: any;
};

const MenuCategoryHeader: React.FC<Props> = (props: Props) => {
  const {category} = props;
  
  return (
    <div className="menu-category-header">
      {category.image && (
        <div
          className="img"
          role="img"
          style={{backgroundImage: `url(${imageUrl(category.image)})`}}></div>
      )}
      <CategoryHeader
        title={props.category.name}
        description={props.category.description}
      />
    </div>
  );
};

export default MenuCategoryHeader;
