import Restaurant from '../restaurant';
import moment from 'moment';
import HistoricOrder from './historicOrder';

export default class HistoricSession {
  restaurant: Restaurant;
  orders: Array<HistoricOrder>;
  sessionId: string;
  date: string;
  price: number;
  get donations(): number {
    return (
      this.orders
        .map((x: HistoricOrder) => parseInt(x.donations))
        .reduce((prev: number, current: number) => prev + current, 0) / 100
    );
  }
  get tips(): number {
    return (
      this.orders
        .map((x: HistoricOrder) => parseInt(x.tips))
        .reduce((prev: number, current: number) => prev + current, 0) / 100
    );
  }
  get totalPrice(): number {
    return this.price + this.donations + this.tips;
  }

  constructor(json: any, restaurant: Restaurant) {
    const uniqueMenuItems: Array<HistoricOrder> = [];
    const allOrders = json.map((order: any) => new HistoricOrder(order));
    allOrders.forEach((order: HistoricOrder) => {
      const uniqueSetEntry = uniqueMenuItems.find((x: HistoricOrder) =>
        x.equals(order),
      );
      if (uniqueSetEntry === undefined) {
        uniqueMenuItems.push(order);
      } else {
        uniqueSetEntry.amount++;
      }
    });

    this.orders = uniqueMenuItems;
    this.restaurant = restaurant;

    this.sessionId = this.getSessionId(json);
    this.date = this.getFirstDate(json);
    this.price = this.getPrice(json);
  }

  getFirstDate = (orders: Array<any>) => {
    const mapped = orders
      .map((x) => moment.utc(x.orderDate, 'YYYY-MM-DD HH:mm:ss'))
      .sort((a, b) => (a.isBefore(b) ? -1 : 1));

    if (mapped.length > 0) {
      return mapped[0].format('ll');
    }

    return 'onbekend';
  };

  getSessionId = (orders: Array<any>) => {
    return `${orders.find((order) => order.sessionId).sessionId}`;
  };

  getPrice = (orders: Array<any>) => {
    return (
      orders.reduce((previous, current) => {
        const price = current.details.price;
        return previous + price;
      }, 0) / 100
    );
  };
}
