import moment, {Moment} from 'moment';

export default class OrderReservation {
  orderType: 'pickup' | 'delivery';
  reservationDate: Moment;

  constructor(json: any) {
    this.orderType = json.orderType;
    this.reservationDate = moment(json.reservationDate, 'YYYY-MM-DD HH:mm:SS');
  }
}
