import React from 'react';
import './featured-item.scss';
import {ReactComponent as Pin} from '../../../../assets/icons/pin-small.svg';
import {DateTime} from 'luxon';

export type Item = {
  image: string;
  title: string;
  location?: string;
  tags?: Record<string, string>;
  category?: string;
  url: string;
  date?: string;
};

const FeaturedItem: React.FC<Item> = ({
  image,
  title,
  location,
  tags = [],
  category,
  url,
  date,
}) => {
  let tagString = '';

  for (const [_, value] of Object.entries(tags)) {
    tagString += `${value.toUpperCase()}${' • '}`;
  }

  if (tagString.length !== 0) {
    tagString = tagString.slice(0, -2);
  }

  return (
    <a href={url}>
      <div className={`featured-item ${date && 'blog'}`}>
        <img src={image} alt={title} />
        {tags.length !== 0 && <p className="tag">{tagString}</p>}
        {category && date && (
          <div className="blog-row">
            <p className="blog-category">{category.toUpperCase()}</p>
            <p className="date">
              {DateTime.fromISO(date).toFormat('dd/LL/yyyy')}
            </p>
          </div>
        )}
        <h3>{title}</h3>

        {location && (
          <div className="location">
            <Pin />
            <p>{location}</p>
          </div>
        )}
      </div>
    </a>
  );
};

export default FeaturedItem;
