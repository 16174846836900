import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ReactComponent as Icon} from '../../../../assets/icons/search-new.svg';
import './search-bar.scss';

const SearchBar: React.FC = () => {
  const [value, setValue] = useState('');

  const {push} = useHistory();

  const openSearch = () => {
    push(`/search?value=${value}`);
  };

  return (
    <div className="search-bar-header">
      <input
        onChange={(e) => setValue(e.target.value)}
        value={value}
        type="text"
        placeholder="Wat zoek je?"
      />
      <div onClick={openSearch} className="icon-button">
        <Icon />
      </div>
    </div>
  );
};

export default SearchBar;
