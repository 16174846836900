import AllergiesList from '../../components/allergies-list/allergies.list.component';
import Toolbar from '../../components/toolbar/toolbar.component';
import Content from '../../components/content/content.component';
import {getProfile} from '../../redux/actions/account.actions';
import {setAllergies} from '../../redux/actions/account.actions';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Bg from '../../utils/bg';
import Button from '../../components/ui-base/button/button.component';
import {ReactComponent as CheckIcon} from '../../assets/icons/check.svg';
import './allergies.styles.scss';
import {AppState} from '../../redux/reducers/root.reducer';
import {RouteComponentProps} from 'react-router-dom';
import {showNotification} from '../../redux/actions/notifications.actions';
import {NotificationType} from '../../models/notification';

type ReduxActions = {
  setAllergies: (token: string, allergies: any) => void;
  showNotification: (
    message: string,
    type: NotificationType,
    duration?: number,
  ) => void;
};
type ReduxProps = {
  loggedIn: boolean;
  allergies: any;
  token: string;
};
type Props = {} & ReduxActions & ReduxProps & RouteComponentProps;

class Allergies extends Component<Props> {
  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.showNotification(
        'U bent niet ingelogd.',
        NotificationType.ERROR,
      );
      this.props.history.push('/login');
    }
  }

  complete = () => {
    const {setAllergies, token, allergies} = this.props;
    setAllergies(token, allergies);
    this.props.history.goBack();
  };

  onToggleAllergy = (allergy: any, checked: boolean) => {
    const {allergies, token} = this.props;
    if (checked) {
      const newAllergies = [...allergies, allergy.id];
      this.props.setAllergies(token, newAllergies);
    } else {
      const newAllergies = allergies.filter((a: string) => a !== allergy.id);
      this.props.setAllergies(token, newAllergies);
    }
  };

  render() {
    const {loggedIn, allergies} = this.props;

    if (!loggedIn) {
      return <></>;
    }

    return (
      <>
        <Bg />
        <Toolbar backLink="/profile" title="Allergenen" />
        <Content toolbar={true}>
          <div className="full-width allergies-content">
            <AllergiesList
              activeAllergies={allergies}
              readonly={false}
              onAllergyToggled={this.onToggleAllergy}
            />
            <Button
              text="Opslaan"
              icon={<CheckIcon className="icon" fill="#ffffff" />}
              className="btn-submit mt-20"
              onClick={this.complete}
            />
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState): ReduxProps => {
  return {
    loggedIn: state.account.loggedIn,
    allergies: state.account.allergies,
    token: state.account.token,
  };
};

export default connect(mapStateToProps, {
  getProfile,
  setAllergies,
  showNotification,
})(Allergies);
