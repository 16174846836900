import React from 'react';
import Formatters from '../../utils/formatters';
import { Button} from '../ui-base';

type Props = {
  disabled?: boolean;
  iDealCost: number | string;
  onClick: () => void;
};

const IdealButton: React.FC<Props> = (props: Props) => {
  return (
    <Button
      disabled={props.disabled}
      text={`iDeal (+${Formatters.formatCurrency(props.iDealCost)})`}
      className="mt-20 full-width"
      onClick={props.onClick}
    />
  );
};

export default IdealButton;
