import React, {Component} from 'react';

import './side.dishes.dialog.styles.scss';
import SideDish, {SideDishOption} from '../../../models/sidedish';
import {MultipleChoiceSideDish} from './multiple-choice-dish/multiple-choice-sidedish';
import SingleChoiceDish from './single-choice-dish/single-choice-dish';

export interface SideDishSelectable extends SideDish {
  selections: Array<SideDishOption>;
}

type Props = {
  onSideDishesChanged: (sideDishes: Array<SideDishSelectable>) => void;
  sideDishes: Array<SideDish>;
};

type State = {
  sideDishes: Array<SideDishSelectable>;
};

export class SideDishes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {sideDishes} = props;
    const options = sideDishes.map((dish) => ({
      ...dish,
      selections: [dish.items[0]],
    }));
    this.state = {
      sideDishes: options,
    };
  }

  onSideDishesSelectionChanged = () => {
    const {sideDishes} = this.state;
    this.props.onSideDishesChanged(sideDishes);
  };

  _renderSingleDish = (dish: SideDishSelectable) => {
    return (
      <SingleChoiceDish
        key={dish.key}
        dish={dish}
        onSelectionChanged={this.onSideDishesSelectionChanged}
      />
    );
  };

  _renderMultipleChoiceDish = (selectableDish: SideDishSelectable) => {
    return (
      <MultipleChoiceSideDish
        key={selectableDish.key}
        dish={selectableDish}
        onSelectionChanged={this.onSideDishesSelectionChanged}
      />
    );
  };

  _renderDish = (dish: SideDishSelectable) => {
    const sideDishComponentRender = dish.multiSelectable
      ? this._renderMultipleChoiceDish
      : this._renderSingleDish;

    return sideDishComponentRender(dish);
  };

  render() {
    const {sideDishes} = this.state;
    return <div>{sideDishes.map((dish) => this._renderDish(dish))}</div>;
  }
}

export default SideDishes;
