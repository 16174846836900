import React, {useState} from 'react';
import {Typography} from '../../../../../../components/ui-base';
import Table from '../../../../../../models/table';
import ItemSelector from '../../item-selector/item-selector';
import {calculateAvailableCapacities} from '../../reservation-helpers';
import './step1.scss';

type Props = {
  amount?: number;
  setSelectedAmount: (amount: number) => void;
  tables: Table[];
  restaurantEmail: string;
  restaurantPhonenumber: string;
};

const Step1: React.FC<Props> = ({
  amount,
  tables,
  restaurantEmail,
  restaurantPhonenumber,
  setSelectedAmount,
}) => {
  const [availableCapacity] = useState(
    calculateAvailableCapacities(tables) ?? [],
  );

  const getMaxPartySize = () => availableCapacity[availableCapacity.length - 1];

  return (
    <div className="step1">
      <ItemSelector<number>
        items={availableCapacity}
        selectedItem={amount}
        setSelectedItem={setSelectedAmount}
        noItemsText="Er is geen selectie beschikbaar"
      />
      <Typography className="contact">
        {`Wil je reserveren voor meer dan ${getMaxPartySize()} personen? Neem dan even contact met ons op via ${restaurantEmail} of ${restaurantPhonenumber}`}
      </Typography>
    </div>
  );
};

export default Step1;
