import React, {ReactNode} from 'react';
import './card.scss';

type Props = {
  children?: ReactNode;
  className?: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Card: React.FC<Props> = (props: Props) => {
  const {className, children, ...leftOver} = props;
  return (
    <div className={`card ${className}`} {...leftOver}>
      {children}
    </div>
  );
};

Card.defaultProps = {
  className: '',
};

export default Card;
