import {Typography} from '../../../../../../components/ui-base';
import Waiter from '../../../../../../assets//images/waiter.png';
import {DateTime} from 'luxon';
import {ReservationData} from '../../../reservation-interfaces';
import {ReactComponent as Persons} from '../../../../../../assets/icons/persons.svg';
import {ReactComponent as Calendar} from '../../../../../../assets/icons/calendar-step.svg';
import {ReactComponent as Clock} from '../../../../../../assets/icons/clock.svg';
import {ReactComponent as Location} from '../../../../../../assets/icons/location-pin-black.svg';
import './confirmation.scss';

type Props = {
  restaurantName: string;
  reservationData: ReservationData;
};

const Confirmation: React.FC<Props> = ({reservationData, restaurantName}) => {
  return (
    <div className="confirmation">
      <img src={Waiter} alt="ober" />
      <div className="text-bubble">
        <Typography className="header4">{`Je reservering bij ${restaurantName} is voltooid`}</Typography>
        <Typography className="copy">
          Je ontvangt een bevestigingsmail op het e-mailadres dat je opgegeven
          hebt. We kijken er naar uit je te mogen ontvangen!
        </Typography>
        <div className="divider" />
        <div className="row">
          <Persons />
          <Typography className="">{`${reservationData.amountOfPersons} Personen`}</Typography>
        </div>
        <div className="divider" />
        <div className="row">
          <Calendar />
          <Typography className="">
            {DateTime?.fromJSDate(
              reservationData?.reservationDate ?? new Date(),
            ).toFormat('dd LLLL yyyy')}
          </Typography>
        </div>
        <div className="divider" />
        <div className="row">
          <Clock /> <Typography>{reservationData.reservationTime}</Typography>
        </div>
        <div className="divider" />
        <div className="row">
          <Location className="fill-dark" />
          <Typography>{reservationData.preferredLocation}</Typography>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
