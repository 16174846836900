import Logger from '../../utils/logger';

const initialState = {
  loggedIn: false,
  allergies: [],
  restaurantFavorites: {},
  menuFavorites: {},
  profileImage: '',
  hideAllergies: false,
  token: '',
};

const AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_ACCOUNT':
      const account = loadAccount();
      Logger.log(`loaded account ${JSON.stringify(account)}`);
      return account;
    case 'SET_ACCOUNT':
      return saveState(setAccount(action.payload, state));
    case 'REMOVE_ACCOUNT':
      return saveState({...initialState});
    case 'SET_ALLERGIES':
      return saveState(setAllergies(action.payload, state));
    case 'ADD_RESTAURANT_FAVORITE':
      return saveState(addRestaurantFavorite(action.payload, state));
    case 'REMOVE_RESTAURANT_FAVORITE':
      return saveState(removeRestaurantFavorite(action.payload, state));
    case 'ADD_MENU_FAVORITE':
      return saveState(addMenuFavorite(action.payload, state));
    case 'REMOVE_MENU_FAVORITE':
      return saveState(removeMenuFavorite(action.payload, state));
    case 'SET_RESTAURANT_FAVORITES':
      return saveState(setRestaurantFavorites(action.payload, state));
    case 'SET_MENU_FAVORITES':
      return saveState(setMenuFavorites(action.payload, state));
    case 'SET_PROFILE_IMAGE':
      return saveState(setProfileImage(action.payload, state));
    case 'SET_TOKEN':
      return saveState(setToken(action.payload, state));
    case 'SET_HIDE_ALLERGIES':
      return saveState(setHideAllergies(action.payload, state));
    default:
      return state;
  }
};

function saveState(state) {
  localStorage.setItem('account', JSON.stringify(state));
  return state;
}

function loadAccount() {
  try {
    const account = localStorage.getItem('account');
    if (account) {
      return JSON.parse(account);
    } else {
      return initialState;
    }
  } catch {
    return initialState;
  }
}

function setAccount(payload, state) {
  return {
    ...state,
    loggedIn: true,
    token: payload.token,
    firstName: payload.firstName,
    email: payload.email,
    uuid: payload.uuid,
    city: payload.city,
    street: payload.street,
    houseNumber: payload.houseNumber,
    lastName: payload.lastName,
    optIn: payload.optIn,
    phoneNumber: payload.phoneNumber,
    postalCode: payload.postalCode,
    comment: payload.comment,
    profileImage: payload.profileImage,
  };
}

function setAllergies(payload, state) {
  return {...state, allergies: payload};
}

function setRestaurantFavorites(payload, state) {
  return {...state, restaurantFavorites: payload};
}

function setMenuFavorites(payload, state) {
  return {...state, menuFavorites: payload};
}

function addRestaurantFavorite(payload, state) {
  const favorites = {...state.restaurantFavorites};
  favorites[payload] = true;
  return {...state, restaurantFavorites: favorites};
}

function removeRestaurantFavorite(payload, state) {
  const favorites = {...state.restaurantFavorites};
  favorites[payload] = false;
  return {...state, restaurantFavorites: favorites};
}

function addMenuFavorite(payload, state) {
  const favorites = {...state.menuFavorites};
  favorites[payload] = true;
  return {...state, menuFavorites: favorites};
}

function removeMenuFavorite(payload, state) {
  const favorites = {...state.menuFavorites};
  favorites[payload] = false;
  return {...state, menuFavorites: favorites};
}

function setProfileImage(payload, state) {
  return {...state, profileImage: payload};
}

function setToken(payload, state) {
  return {...state, token: payload};
}

function setHideAllergies(payload, state) {
  return {...state, hideAllergies: payload};
}

export default AccountReducer;
