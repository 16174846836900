import React from 'react';
import AspectRatioContainer from '../aspect-ratio-container/aspect-ratio-container.component';
import header from '../../assets/images/dish.png';

import './dialog-image-header.styles.scss';

type Props = {
  imageUrl?: string;
};

const DialogImageHeader: React.FC<Props> = (props: Props) => (
  <AspectRatioContainer ratio="56.6">
    <img
      src={props.imageUrl}
      className="full-image modal-dialog-image-header cover"
      alt="header"
    />
  </AspectRatioContainer>
);

DialogImageHeader.defaultProps = {
  imageUrl: header,
};

export default DialogImageHeader;
