import placeholder_small from '../../assets/images/placeholder_small.png';
import createImageUrl from '../../utils/scalar';
import React, {useEffect, useState} from 'react';
import './visit.item.style.scss';
import HistoricSession from '../../models/history/historicSession';

type Props = {
  visit: HistoricSession;
  onClick: (visit: HistoricSession) => void;
};

const VisitItem: React.FC<Props> = (props: Props) => {
  const {visit, onClick} = props;
  const [imageUrl, setImageUrl] = useState<string>(placeholder_small);
  useEffect(() => {
    if (
      visit.restaurant.profileImage &&
      visit.restaurant.profileImage.length > 0
    ) {
      setImageUrl(createImageUrl(visit.restaurant.profileImage));
    }
  }, [visit.restaurant.profileImage]);

  return (
    <div className="visit_item" onClick={() => onClick(visit)}>
      <img src={imageUrl} className="image" alt="restaurant" />
      <div className="info">
        <h1 className="title">{visit.restaurant.title}</h1>
        <h2 className="description">{`Bezocht op ${visit.date}`}</h2>
        <span className="price">€{visit.totalPrice.toFixed(2)}</span>
      </div>
    </div>
  );
};

export default VisitItem;
