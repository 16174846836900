import L, {ErrorEvent, LatLng, LocationEvent} from 'leaflet';
import React, {useEffect, useState} from 'react';
import {Marker, useMapEvents} from 'react-leaflet';

import LocationIcon from '../../assets/icons/my-location.svg';
import {NotificationType} from '../../models/notification';
import {showNotification} from '../../redux/actions/notifications.actions';
import {useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import {GPSState} from '../../screens/map/gps-state';
import {
  LOCATION_ERROR_LOCATION_ERROR,
  LOCATION_ERROR_PERMISSION_DENIED,
  LOCATION_ERROR_TIMEOUT,
} from './restaurants-map';

type Props = {
  gpsState: GPSState;
};

const myLocationIcon = L.icon({
  iconUrl: LocationIcon,
  iconSize: new L.Point(36, 36),
  iconAnchor: new L.Point(18, 36),
});

export const UserLocationMarker: React.FC<Props> = (props: Props) => {
  const [position, setPosition] = useState<LatLng>();
  const appThunkDispatch = useAppThunkDispatch();
  const map = useMapEvents({
    locationfound(e: LocationEvent) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
    locationerror(error: ErrorEvent) {
      if (error.code === LOCATION_ERROR_PERMISSION_DENIED) {
        appThunkDispatch(
          showNotification(
            'Locatie permissie geweigerd',
            NotificationType.ERROR,
          ),
        );
      } else if (error.code === LOCATION_ERROR_LOCATION_ERROR) {
        appThunkDispatch(
          showNotification(
            'Huidige locatie niet beschikbaar',
            NotificationType.ERROR,
          ),
        );
      } else if (error.code === LOCATION_ERROR_TIMEOUT) {
        appThunkDispatch(
          showNotification(
            'Timeout opgetreden bij het bepalen van de huidige locatie',
            NotificationType.ERROR,
          ),
        );
      }
    },
  });

  useEffect(() => {
    if (props.gpsState === GPSState.FIXED) {
      map.locate({watch: true, timeout: 10000});
    }
    return () => {
      map.stopLocate();
    };
  }, [props.gpsState, map]);

  return (
    <>{position && <Marker position={position} icon={myLocationIcon} />}</>
  );
};
