import {ReactComponent as DropdownIcon} from '../../../assets/icons/dropdown.svg';
import classify from '../../../utils/classify';
import React, {Component, ChangeEvent} from 'react';
import './select.style.scss';

type Props = {
  autoFocus?: boolean;
  className?: string;
  value?: string;
  invalid?: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
};

export default class Select extends Component<Props> {
  render() {
    const {value, invalid, onChange, autoFocus, disabled} = this.props;
    const extraProps = {value, onChange, disabled, autoFocus};
    return (
      <div className={`select_container ${invalid ? 'invalid' : ''}`}>
        <select className={classify('select', this.props)} {...extraProps}>
          {this.props.children}
        </select>
        <div className="select_icon">
          <DropdownIcon className="icon" fill="#E7AC5B" />
        </div>
      </div>
    );
  }
}
