import React, {useEffect, useState} from 'react';
import TopSection from './top-section/top-section';
import MiddleSection from './middle-section/middle-section';
import BottomSection from './bottom-section/bottom-section';
import './landing-page.styles.scss';
import {isMobileOrTablet} from '../../utils/isMobileOrTablet';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import FeaturedItems from './featured-items/featured-items';
import {Item} from './components/featured-item/featured-item';
import api from '../../services/api/apiv2';
import Faq from './faq/faq';
import {FaqItemProps} from './faq/components/faq-item';
import HowItWorks from './how-it-works/how-it-works';

type Props = {} & RouteComponentProps;

export const LandingPageComponent: React.FC<Props> = () => {
  const {push} = useHistory();
  const [blogs, setBlogs] = useState<Item[]>([]);
  const [restaurants, setRestaurants] = useState<Item[]>([]);
  const [faq, setFaq] = useState<FaqItemProps[]>([]);

  const shouldGoToHome = isMobileOrTablet();

  const fetchBlogs = async () => {
    const response = await api.blogs.getBlogs();
    setBlogs(response);
  };

  const fetchRestaurants = async () => {
    const response = await api.restaurants.getFeaturedRestaurants();
    setRestaurants(response);
  };

  const fetchFaq = async () => {
    const response = await api.faq.getFaq();
    setFaq(response);
  };

  useEffect(() => {
    fetchBlogs();
    fetchRestaurants();
    fetchFaq();
  }, []);

  const onResize = () => {
    if (window.innerWidth < 1200) {
      setTimeout(() => push('/home'), 0);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (shouldGoToHome) {
      setTimeout(() => push('/home'), 0);
    }
  }, [shouldGoToHome, push]);

  return (
    <div className="landing-page">
      <TopSection />
      <FeaturedItems
        items={restaurants}
        showMoreUrl={'/'}
        title={'Selectie van Amuse'}
      />
      <HowItWorks />
      <MiddleSection />
      <FeaturedItems offWhite items={blogs} showMoreUrl={'/'} title={'Blogs'} />
      <Faq items={faq} />
      <BottomSection />
    </div>
  );
};

export default LandingPageComponent;
