/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import './card.scss';

type Props = {
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
};

const Card: React.FC<Props> = ({children, className = '', onClick, style}) => (
  <div style={style} onClick={onClick} className={`card ${className}`}>
    {children}
  </div>
);

export default Card;
