import React from 'react';
import CollapsableCard from './components/collapsable-card';
import {ReactComponent as Pin} from '../../../assets/icons/pin-large.svg';
import device from '../../../assets/images/iPhone.png';
import './how-it-works.scss';

const HowItWorks: React.FC = () => {
  return (
    <div className="off-white">
      <div className="how-it-works ">
        <div className="container">
          <div>
            <h2>Amuse in een notendop</h2>
            <p className="tagline">#supersimpel</p>
            <div className="cards">
              <CollapsableCard
                text="Heb je zin in een gezellig en lekker uitje? Zoek op bijvoorbeeld restaurantnaam, locatie, keuken of zelfs het gerecht waar je zin in hebt en vind het restaurant dat echt bij je past."
                icon={Pin}
                title="Zoek en vind de leukste horecazaken!"
              />
              <CollapsableCard
                text="Heb je een gerecht gevonden waar je wel zin in hebt? Bekijk direct de pagina van het restaurant en de menukaart om te zien wat ze nog meer te bieden hebben. Natuurlijk met alle informatie die je nodig hebt, zoals foto's van de gerechten, allergenen informatie en veel meer."
                icon={Pin}
                title="Bekijk direct de menukaart(en)"
              />
              <CollapsableCard
                text="Heb je het restaurant gevonden waar je wel een hapje wilt gaan eten? Reserveer dan direct heel eenvoudig een tafel via Amuse. Dan weet je zeker dat er een plekje voor je is."
                icon={Pin}
                title="Reserveer eenvoudig online"
              />
              <CollapsableCard
                text="Heb je geen zin om te reserveren omdat je nu meteen naar het restaurant wilt? Check dan gewoon even de druktemeter of er nog plek is. Dan weet je zeker dat ze je met open armen zullen ontvangen voor een gezellige middag of avond."
                icon={Pin}
                title="Realtime druktemeter"
              />
              <CollapsableCard
                text="En als je binnenkomt, heb je de menukaart via Amuse meteen bij de hand en kun jij bestellen wat je wilt, wanneer jij dat wilt. Hoef je niet meer om je heen te kijken en te wachten tot de bediening tijd voor je heeft."
                icon={Pin}
                title="Bestel via de digitale menukaart"
              />
              <CollapsableCard
                text="En maak een eigen account aan op Amuse, zodat wij je kunnen verrassen met leuke uitnodigingen, acties en veel meer!"
                icon={Pin}
                title="Je eigen account op Amuse"
              />
            </div>
          </div>
        </div>
        <div className="phone-pos">
          <img loading="lazy" src={device} alt="Amuse homepagina" />
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
