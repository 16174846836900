import React from 'react';
import {useSelector} from 'react-redux';

import Restaurant from '../../models/restaurant';
import {
  addRestaurantFavorite,
  removeRestaurantFavorite,
} from '../../redux/actions/account.actions';
import {AppState, useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import {FavoriteIconButton} from '../favorite-icon-button/favorite-icon-button.component';

type Props = {
  className?: string;
  restaurant: Restaurant;
};

export const RestaurantFavoriteButton: React.FC<Props> = (props: Props) => {
  const dispatch = useAppThunkDispatch();
  const favorites = useSelector<AppState, {[key: string]: boolean}>(
    (state) => state.account.restaurantFavorites,
  );
  const accountToken = useSelector<AppState, string>(
    (state) => state.account.token,
  );

  const {restaurant} = props;

  const addFavorite = () => {
    dispatch(addRestaurantFavorite(accountToken, restaurant.id));
  };
  const removeFavorite = () => {
    dispatch(removeRestaurantFavorite(accountToken, restaurant.id));
  };
  const isFavorite = favorites[props.restaurant.id];
  const clickHandler = isFavorite ? removeFavorite : addFavorite;

  return (
    <FavoriteIconButton
      favorited={isFavorite}
      className={props.className}
      onClick={clickHandler}
    />
  );
};
