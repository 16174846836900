import './restaurant.eat.drink.style.scss';
import React, {Component} from 'react';

enum EatDrinkOption {
  SELECTED_FOOD = 0,
  SELECTED_DRINKS = 1,
}

type Props = {
  onChange: (foodIsSelected: boolean) => void;
};

type State = {
  selected: EatDrinkOption;
};

export default class RestaurantEatDrinkComponent extends Component<
  Props,
  State
> {
  state = {
    selected: EatDrinkOption.SELECTED_FOOD,
  };

  selectFood = () => {
    this.select(EatDrinkOption.SELECTED_FOOD);
  };

  selectDrinks = () => {
    this.select(EatDrinkOption.SELECTED_DRINKS);
  };

  select = (selection: EatDrinkOption) => {
    this.setState({selected: selection});
    this.props.onChange(selection === EatDrinkOption.SELECTED_FOOD);
  };

  render() {
    const {selected} = this.state;

    return (
      <div className="restaurant_eatdrink_container full-width">
        <div className="restaurant_eatdrink_item">
          <button
            className={`restaurant_eatdrink_button ${
              selected === EatDrinkOption.SELECTED_FOOD && 'selected'
            }`}
            onClick={this.selectFood}>
            Eten
          </button>
        </div>
        <div className="restaurant_eatdrink_item">
          <button
            className={`restaurant_eatdrink_button ${
              selected === EatDrinkOption.SELECTED_DRINKS && 'selected'
            }`}
            onClick={this.selectDrinks}>
            Drinken
          </button>
        </div>
      </div>
    );
  }
}
