import './restaurant.item.style.scss';
import React, {useMemo} from 'react';
import {ReactComponent as LocationIcon} from '../../assets/icons/location.svg';
import placeholder_small from '../../assets/images/placeholder_small.png';
import Restaurant from '../../models/restaurant';
import imageUrl from '../../utils/scalar';
import {RestaurantFavoriteButton} from '../restaurant-favorite-button/restaurant-favorite-button.component';
import {getDistanceFromLatLonInKm} from '../../utils/getDistanceFromLatLon';

type UserLocation = {
  latitude: number;
  longitude: number;
};

type InputProps = {
  restaurant: Restaurant;
  onClick: (restaurant: Restaurant) => void;
  userLocation?: UserLocation;
};

type Props = InputProps;

const RestaurantItem: React.FC<Props> = ({
  onClick,
  restaurant,
  userLocation,
}) => {
  const distance = useMemo(() => {
    if (userLocation) {
      const km = getDistanceFromLatLonInKm(
        userLocation.latitude,
        userLocation.longitude,
        parseFloat(restaurant.latitude),
        parseFloat(restaurant.longitude),
      );
      if (km) {
        return Math.round(km * 10) / 10;
      }
    }
  }, [restaurant.latitude, restaurant.longitude, userLocation]);

  let imgUrl = placeholder_small;
  if (restaurant.profileImage && restaurant.profileImage.length > 0) {
    imgUrl = imageUrl(restaurant.profileImage);
  }

  const location = `${restaurant.street} ${restaurant.streetNumber}, ${restaurant.city}`;
  const description = Object.keys(restaurant.labels)
    .map((key) => restaurant.labels[key])
    .join(', ');

  return (
    <div className="restaurant_item" onClick={() => onClick(restaurant)}>
      <img src={imgUrl} className="restaurant_item_image" alt="restaurant" />
      <div className="restaurant_item_info">
        <h1 className="restaurant_item_title">{restaurant.title}</h1>
        <h2 className="restaurant_item_description">
          {description.split(',').join(', ')}
        </h2>
        <span className="restaurant_item_location">
          <LocationIcon className="restaurant_item_location_icon" /> {location}{' '}
          ({distance ?? '< 1 '}km)
        </span>
      </div>
      <RestaurantFavoriteButton
        restaurant={restaurant}
        className="restaurant_item_like"
      />
    </div>
  );
};

export default RestaurantItem;
