import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {removeFilter} from '../../redux/search/search.thunks';
import React, {Component} from 'react';
import './search-result.style.scss';
import {connect} from 'react-redux';
import Filter, {QueryFilter} from '../../redux/search/filter';

type ReduxActions = {
  removeFilter: (toRemove: Filter) => void;
};

type Props = {
  query: QueryFilter;
} & ReduxActions;

class SearchResult extends Component<Props> {
  render() {
    const {query} = this.props;

    return (
      <div className="search_result">
        <p className="search_result_text">{`Resultaten voor "${query.value}"`}</p>
        <CloseIcon className="search_result_close" onClick={this.remove} />
      </div>
    );
  }

  remove = () => {
    const {query} = this.props;
    this.props.removeFilter(query);
  };
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {removeFilter})(SearchResult);
