import React, {useMemo} from 'react';

import {ReactComponent as GPSFixedIcon} from '../../assets/icons/gps-fixed.svg';
import {ReactComponent as GPSNotAvailableIcon} from '../../assets/icons/gps-not-available.svg';
import {ReactComponent as GPSNotFixedIcon} from '../../assets/icons/gps-not-fixed.svg';
import FloatingButton from '../../components/ui-base/floating-button/floating.button.component';
import {GPSState} from './gps-state';

type Props = {
  gpsState: GPSState;
  onClick: () => void;
};

export const GPSButton: React.FC<Props> = (props: Props) => {
  let gpsIcon = useMemo(() => {
    if (props.gpsState === GPSState.FIXED) {
      return <GPSFixedIcon />;
    } else if (props.gpsState === GPSState.INACTIVE) {
      return <GPSNotFixedIcon />;
    } else if (props.gpsState === GPSState.UNAVAILABLE) {
      return <GPSNotAvailableIcon />;
    }
  }, [props.gpsState]);

  return <FloatingButton onClick={props.onClick}>{gpsIcon}</FloatingButton>;
};
