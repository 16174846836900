import api from '../../services/api';
import {searchMenuItems} from '../actions/restaurant.menu.actions';
import {getRestaurants} from '../actions/restaurants.actions';
import {AppThunk} from '../reducers/root.reducer';
import Filter, {FilterType, QueryFilter, QueryType, SortFilter} from './filter';
import {
  addFilterAction,
  labelsRetrievedAction,
  removeFilterAction,
} from './search.action';

export const addFilter =
  (
    filterType: FilterType,
    value: string,
    displayValue: string,
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(
      addFilterAction(new Filter(filterType, value, displayValue)),
    );
    await dispatch(applyFilter());
  };

export const addQueryFilter =
  (queryType: QueryType, query: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(addFilterAction(new QueryFilter(queryType, query)));
    await dispatch(applyFilter());
  };

export const setSort =
  (sort: SortFilter): AppThunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(addFilterAction(sort));
    await dispatch(applyFilter());
  };

export const removeFilter =
  (filter: Filter): AppThunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(removeFilterAction(filter));
    await dispatch(applyFilter());
  };

export const getLabels =
  (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    const labels = await api.getLabels();

    dispatch(labelsRetrievedAction(labels));
  };

const applyFilter =
  (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    const currentState = getState();
    const queryFilter = currentState.search.activeFilters.find(
      (x) => x.type === FilterType.Query,
    ) as QueryFilter | undefined;

    if (queryFilter && queryFilter.queryType === QueryType.Menu) {
      dispatch(searchMenuItems(currentState.search.activeFilters));
    } else {
      dispatch(getRestaurants(currentState.search.activeFilters));
    }
  };
