import {capitalize} from 'lodash';
import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {AppState, useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import Filter, {FilterType} from '../../redux/search/filter';
import {addFilter, removeFilter} from '../../redux/search/search.thunks';
import {InputGroup, Label, Select} from '../ui-base';
import './location.picker.styles.scss';

type ReduxProps = {
  cities: Array<string>;
  selectedFilter: Filter | undefined;
};

type ReduxActions = {
  addFilter: (
    filterType: FilterType,
    value: string,
    displayValue: string,
  ) => Promise<void>;
};

type Props = ReduxProps & ReduxActions;

const LocationPicker: React.FC<Props> = ({selectedFilter, cities}) => {
  const [selectedValue, setSelectedValue] = useState(selectedFilter?.value);
  const dispatch = useAppThunkDispatch();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedValue(e.target.value);
      if (e.target.value === 'Selecteer locatie') {
        dispatch(
          removeFilter(
            new Filter(FilterType.Location, e.target.value, e.target.value),
          ),
        );
      } else {
        dispatch(
          addFilter(FilterType.Location, e.target.value, e.target.value),
        );
      }
    },
    [dispatch],
  );

  return (
    <InputGroup className="location-picker">
      <Label>Locatie</Label>
      <Select
        className="location-select"
        value={selectedValue}
        onChange={onChange}>
        {cities.map((x) => (
          <option key={x}>{x}</option>
        ))}
      </Select>
    </InputGroup>
  );
};

const mapStateToProps = (state: AppState) => {
  const mappedCities = [
    'Selecteer locatie',
    ...state.restaurants.restaurants
      .map((x) => x.city.toLowerCase())
      .sort((a, b) => a.localeCompare(b)),
  ];
  const selectedFilter = state.search.activeFilters.find(
    (x) => x.type === FilterType.Location,
  );
  return {
    cities: mappedCities
      .filter((city, index) => mappedCities.indexOf(city) === index)
      .map((city) => capitalize(city)),
    selectedFilter,
  };
};

export default connect(mapStateToProps, {addFilter})(LocationPicker);
