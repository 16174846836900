import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Content from '../../components/content/content.component';
import ToolbarComponent from '../../components/toolbar/toolbar.component';
import {
  addFilter,
  getLabels,
  removeFilter,
} from '../../redux/search/search.thunks';
import Filter, {FilterType, SortFilter} from '../../redux/search/filter';
import {useSelector} from 'react-redux';
import {AppState, useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import RestaurantLabel from '../../models/restaurantLabel';
import './filters.style.scss';
import {Button, Checkbox, InputGroup, Label} from '../../components/ui-base';
import Formatters from '../../utils/formatters';
import RadioButton from '../../components/ui-base/radio-button/radio-button';
import PriceRange from '../../components/price-range/price-range-filter.component';
import {ReactComponent as CheckIcon} from '../../assets/icons/check.svg';
import {useHistory} from 'react-router';

type MealTypeFilter = {
  label: {
    title: string;
  };
  type: string;
  mealType: string;
};

type Sortable = {
  label: {title: string};
  type: 'sort';
  sort: 'a-z' | 'z-a' | 'category' | 'city';
  filter: SortFilter;
};

type Props = {};

const Filters: React.FC<Props> = () => {
  const dispatch = useAppThunkDispatch();
  const history = useHistory();

  const labels = useSelector((state: AppState) => state.search.labels);
  const currentMealTypeFilter = useSelector((state: AppState) =>
    state.search.activeFilters.find((x) => x.type === FilterType.Mealtype),
  );

  const currentSelectedCategories = useSelector((state: AppState) =>
    state.search.activeFilters.filter((x) => x.type === FilterType.Label),
  );
  const currentSelectedSort = useSelector((state: AppState) =>
    state.search.activeFilters.find((x) => x.type === FilterType.Sort),
  );
  const [currentSort, setCurrentSort] = useState(currentSelectedSort?.value);
  const [currentMealtype, setCurrentMealtype] = useState(
    currentMealTypeFilter?.value,
  );
  const [labelsToShow, setLabelsToShow] = useState(labels);
  const inputRef = useRef<HTMLInputElement>(null);

  const checkedCategories = useMemo(() => {
    let newCheckedCategories = [];
    for (const label of labels) {
      let found = false;
      for (const category of currentSelectedCategories) {
        if (category.value === label.label) {
          found = true;
        }
      }
      newCheckedCategories.push(found);
    }
    return newCheckedCategories;
  }, [currentSelectedCategories, labels]);

  useEffect(() => {
    dispatch(getLabels());
  }, [dispatch]);

  const onSelectSort = useCallback(
    (value: string) => {
      const label = sortables.find((s) => s.sort === value)?.label.title;
      if (label) {
        dispatch(addFilter(FilterType.Sort, value, label));
        setCurrentSort(value);
      }
    },
    [dispatch],
  );

  const onSelectMealtype = useCallback(
    (value: string) => {
      const label = mealTypeFilters.find((m) => m.mealType === value)?.label
        .title;
      if (label) {
        dispatch(addFilter(FilterType.Mealtype, value, label));
        setCurrentMealtype(value);
      }
    },
    [dispatch],
  );

  const onSelectCategory = useCallback(
    (value: boolean, index: number, label: RestaurantLabel) => {
      if (!checkedCategories[index]) {
        dispatch(
          addFilter(
            FilterType.Label,
            label.label,
            Formatters.capitalize(label.label),
          ),
        );
      } else {
        dispatch(
          removeFilter(
            new Filter(
              FilterType.Label,
              label.label,
              Formatters.capitalize(label.label),
            ),
          ),
        );
      }
      checkedCategories[index] = value;
    },
    [checkedCategories, dispatch],
  );

  const sortElements = useMemo(() => {
    const options = sortables.map((sortable) => {
      return {
        label: sortable.label.title,
        value: sortable.sort,
      };
    });
    return options.map((option, i) => {
      return (
        <div className="radio-option" key={i}>
          <RadioButton
            isSelected={currentSort === option.value}
            onSelect={() => onSelectSort(option.value)}></RadioButton>
          <Label>{option.label}</Label>
        </div>
      );
    });
  }, [currentSort, onSelectSort]);

  const mealtypeElements = useMemo(() => {
    const options = mealTypeFilters.map((filter) => {
      return {label: filter.label.title, value: filter.mealType};
    });
    return options.map((option, i) => {
      return (
        <div className="radio-option" key={i}>
          <RadioButton
            isSelected={currentMealtype === option.value}
            onSelect={() => onSelectMealtype(option.value)}></RadioButton>
          <Label>{option.label}</Label>
        </div>
      );
    });
  }, [currentMealtype, onSelectMealtype]);

  const categoryElements = useMemo(() => {
    const currentLabels = inputRef.current?.value ? labelsToShow : labels;
    return currentLabels.map((label, i) => {
      return (
        <div className="option" key={i}>
          <Checkbox
            onChecked={(value: boolean) => onSelectCategory(value, i, label)}
            text={label.title}
            checked={
              checkedCategories[
                labels.findIndex((label2) => label2.title === label.title)
              ]
            }></Checkbox>
        </div>
      );
    });
  }, [checkedCategories, onSelectCategory, labelsToShow, labels]);

  const onQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value;
      const newLabelsToShow = labels.filter((label) =>
        label.title.toLowerCase().includes(query.toLowerCase()),
      );
      setLabelsToShow(newLabelsToShow);
    },
    [labels],
  );

  return (
    <Content className="filters" toolbar={true}>
      <ToolbarComponent title="Filteren" back />
      <span className="header">Sorteren</span>
      <InputGroup className="radio-group">{sortElements}</InputGroup>

      <span className="header">Soort restaurant</span>
      <InputGroup className="radio-group"> {mealtypeElements}</InputGroup>
      <span className="header">Prijsklasse</span>
      <PriceRange></PriceRange>
      <span className="header">Categorie</span>
      <input
        ref={inputRef}
        className="searchbar"
        onChange={onQueryChange}
        placeholder="Zoek in categoriëen"
      />
      {categoryElements}
      <Button
        text="Opslaan"
        icon={<CheckIcon className="icon" fill="#ffffff" />}
        className="btn-submit mt-20"
        onClick={() => history.goBack()}
      />
    </Content>
  );
};

const mealTypeFilters: Array<MealTypeFilter> = [
  {label: {title: 'Alle restaurants'}, type: 'mealtype', mealType: ''},
  {label: {title: 'Afhaalrestaurants'}, type: 'mealtype', mealType: 'takeout'},
  {label: {title: 'Bezorgrestaurants'}, type: 'mealtype', mealType: 'delivery'},
  {label: {title: 'To-go restaurants'}, type: 'mealtype', mealType: 'to-go'},
];

const sortables: Array<Sortable> = [
  {
    label: {title: 'Sorteer van A tot Z'},
    type: 'sort',
    sort: 'a-z',
    filter: SortFilter.AlphabeticAscending,
  },
  {
    label: {title: 'Sorteer van Z tot A'},
    type: 'sort',
    sort: 'z-a',
    filter: SortFilter.AlphabeticDescending,
  },
  {
    label: {title: 'Sorteer op categorie'},
    type: 'sort',
    sort: 'category',
    filter: SortFilter.Category,
  },
  {
    label: {title: 'Sorteer op plaats'},
    type: 'sort',
    sort: 'city',
    filter: SortFilter.Location,
  },
];

export default Filters;
