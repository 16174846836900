import React, {ReactNode} from 'react';
import Dialog from '../dialog/dialog.component';
import Modal from '../modal/modal.component';

type Props = {
  children: ReactNode;
  className?: string;
  center?: boolean;
  wrap?: boolean;
  title?: string;
};

const ModalDialog = (props: Props) => {
  return (
    <Modal>
      <Dialog
        className={props.className}
        center={props.center}
        wrap={props.wrap}>
        {props.title && <h1 className="header">{props.title}</h1>}
        {props.children}
      </Dialog>
    </Modal>
  );
};

ModalDialog.defaultProps = {
  center: true,
  wrap: false,
};

export default ModalDialog;
