import {useState} from 'react';
import {ReactComponent as ChevronDown} from '../../../../assets/icons/chevron-down.svg';
import {ReactComponent as ChevronUp} from '../../../../assets/icons/chevron-up.svg';
import Card from '../../components/card/card';
import './faq-item.scss';

export type FaqItemProps = {
  question: string;
  answer: string;
};

const FaqItem: React.FC<FaqItemProps> = ({answer, question}) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Card
      onClick={() => {
        setCollapsed(!collapsed);
      }}
      className=" faq-item">
      <div className="full-width">
        <h3>{question}</h3>
        {!collapsed && <p>{answer}</p>}
      </div>
      <div className="answer">
        {collapsed ? <ChevronDown /> : <ChevronUp />}
      </div>
    </Card>
  );
};

export default FaqItem;
