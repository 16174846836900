import React, { Component } from 'react';
import "./divider.scss";

export class Divider extends Component {
    render() {
        return (
            <div className="divider">
                
            </div>
        )
    }
}

export default Divider
