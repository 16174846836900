type ClassifyProps = {
  className?: string;
};

const classify = (input: string, props: ClassifyProps) => {
  let {className} = props;
  var parsed: Array<string> = [];
  if (!className) {
    parsed = [];
  } else {
    parsed = className.split(' ');
  }
  return [input, ...parsed].join(' ');
};

export default classify;
