import ProfileHeader from '../../components/profile-header/profile.header.component';
import AllergiesList from '../../components/allergies-list/allergies.list.component';
import {showNotification} from '../../redux/actions/notifications.actions';
import {NotificationType} from '../../models/notification';
import NotAuthenticatedError from '../../models/errors/notAuthenticated';
import {
  getProfile,
  updateProfile,
  setHideAllergies,
  logout,
} from '../../redux/actions/account.actions';
import Content from '../../components/content/content.component';
import Toolbar from '../../components/toolbar/toolbar.component';
import {Component} from 'react';
import {connect} from 'react-redux';
import './profile.screen.scss';
import {AppState} from '../../redux/reducers/root.reducer';
import {RouteChildrenProps} from 'react-router-dom';
import Profile from '../../models/profile';
import {
  Checkbox,
  Input,
  InputGroup,
  Label,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui-base';
import Backdrop from '../../components/backdrop/backdrop.component';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import YesNoDialog from '../../sections/yes-no-dialog/yes.no.dialog.component';
import TextInputDialog from '../../components/text-input-dialog/text-input-dialog.component';
import api from '../../services/api/apiv2';

type ReduxActions = {
  getProfile: (token: string) => Promise<void>;
  logout: () => void;
  updateProfile: (token: string, profile: Profile) => Promise<void>;
  setHideAllergies: (hide: boolean) => Promise<void>;
  showNotification: (
    message: string,
    type: NotificationType,
    duration?: number,
  ) => void;
};

type ReduxProps = {
  loggedIn: boolean;
  account: any;
  allergies: Array<any>;
};

type Props = {} & ReduxActions & ReduxProps & RouteChildrenProps;

type State = {
  email?: string;
  optIn?: boolean;
  phoneNumber?: string;
  street?: string;
  houseNumber?: string;
  postalCode?: string;
  city?: string;
  comment?: string;
  removeAccountConfirmVisible: boolean;
  removeAccountInputEmailVisible: boolean;
};

class ProfileScreen extends Component<Props, State> {
  state = {
    email: undefined,
    optIn: undefined,
    phoneNumber: undefined,
    street: undefined,
    houseNumber: undefined,
    postalCode: undefined,
    city: undefined,
    comment: undefined,
    removeAccountConfirmVisible: false,
    removeAccountInputEmailVisible: false,
  };

  componentDidMount() {
    setTimeout(this.fetchProfile, 10);
  }

  updateProfile = () => {
    const {account, updateProfile} = this.props;
    const {phoneNumber, street, houseNumber, postalCode, city, optIn} =
      this.state;
    const profile = new Profile(account);
    profile.phoneNumber =
      phoneNumber !== undefined ? phoneNumber! : profile.phoneNumber;
    profile.street = street !== undefined ? street! : profile.street;
    profile.houseNumber =
      houseNumber !== undefined ? houseNumber! : profile.houseNumber;
    profile.postalCode =
      postalCode !== undefined ? postalCode! : profile.postalCode;
    profile.city = city !== undefined ? city! : profile.city;
    profile.optIn = optIn !== undefined ? optIn! : profile.optIn;
    updateProfile(account.token, profile);
  };

  render() {
    const {loggedIn, account, allergies, setHideAllergies, history} =
      this.props;
    const {
      phoneNumber,
      street,
      houseNumber,
      postalCode,
      city,
      optIn,
      email,
      removeAccountConfirmVisible,
      removeAccountInputEmailVisible,
    } = this.state;

    if (!loggedIn) {
      return <></>;
    }

    const hideAllergies = account.hideAllergies === true;

    return (
      <>
        <Toolbar showMenuIcon={true} title="Profiel" />

        <Content toolbar={true}>
          <ProfileHeader
            name={account.firstName}
            onHistoryClicked={() => history.push('/visits')}
          />
          <div className="full-width content-section">
            <Backdrop />
            <Card className="profile-info">
              <CardHeader>
                <CardTitle>Profiel gegevens</CardTitle>
              </CardHeader>
              <CardContent>
                <InputGroup>
                  <Label>Voornaam</Label>
                  <Input
                    disabled={true}
                    type="text"
                    value={account.firstName}
                  />
                </InputGroup>
                <InputGroup>
                  <Label>Achternaam</Label>
                  <Input disabled={true} type="text" value={account.lastName} />
                </InputGroup>
                <InputGroup>
                  <Label>E-Mailadres</Label>
                  <Input
                    type="text"
                    value={email !== undefined ? email : account.email}
                    onChange={(event) => {
                      this.setState({email: event.target.value});
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <Label>Telefoonnummer</Label>
                  <Input
                    type="text"
                    value={
                      phoneNumber !== undefined
                        ? phoneNumber
                        : account.phoneNumber
                    }
                    onChange={(event) => {
                      this.setState({phoneNumber: event.target.value});
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <Label>Stad</Label>
                  <Input
                    type="text"
                    value={city !== undefined ? city : account.city}
                    onChange={(event) => {
                      this.setState({city: event.target.value});
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <Label>Straatnaam</Label>
                  <Input
                    type="text"
                    value={street !== undefined ? street : account.street}
                    onChange={(event) => {
                      this.setState({street: event.target.value});
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <Label>Huisnummer</Label>
                  <Input
                    type="text"
                    value={
                      houseNumber !== undefined
                        ? houseNumber
                        : account.houseNumber
                    }
                    onChange={(event) => {
                      this.setState({houseNumber: event.target.value});
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <Label>Postcode</Label>
                  <Input
                    type="text"
                    value={
                      postalCode !== undefined ? postalCode : account.postalCode
                    }
                    onChange={(event) => {
                      this.setState({postalCode: event.target.value});
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <Checkbox
                    checked={optIn !== undefined ? optIn : account.optIn}
                    onChecked={(isChecked) => this.setState({optIn: isChecked})}
                    text="Ik wil graag op de hoogte blijven van Amuse en aanbiedingen ontvangen"
                  />
                </InputGroup>
                <Button text="Profiel bijwerken" onClick={this.updateProfile} />
              </CardContent>
            </Card>
          </div>
          <div className="full-width content-section">
            <div className="backdrop" />
            <div className="filter-menu">
              <h3>Allergenen</h3>
              <div
                className="add-new"
                onClick={() => history.push('/allergies')}
              />
            </div>
            <Checkbox
              text="Verberg gerechten die allergenen bevatten"
              checked={hideAllergies}
              onChecked={(hide: boolean) => setHideAllergies(hide)}
            />
            <AllergiesList activeAllergies={allergies} readonly={true} />
          </div>

          <div className="full-width content-section">
            <div className="backdrop" />
            <Card className="profile-info">
              <CardHeader>
                <CardTitle>Profiel verwijderen</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text">
                  Wil je al je gegevens laten verwijderen? Klik hieronder om je
                  account en al je persoonsgegevens te verwijderen.
                </p>
                <Button
                  icon={<DeleteIcon className="icon" />}
                  text="Verwijder mijn gegevens"
                  onClick={this.confirmRemoveProfile}
                />
              </CardContent>
            </Card>
          </div>
        </Content>
        {removeAccountConfirmVisible && (
          <YesNoDialog
            title="Weet je het zeker?"
            description="Het verwijderen van je gegevens kan niet ongedaan gemaakt worden"
            onComplete={() =>
              this.setState({removeAccountInputEmailVisible: true})
            }
            onCancel={() => this.setState({removeAccountConfirmVisible: false})}
          />
        )}
        {removeAccountInputEmailVisible && (
          <TextInputDialog
            title="Account verwijderen"
            description="Voer ter bevestiging je je e-mailadres in."
            inputLabel="E-Mailadres"
            inputPlaceholder="voorbeeld@voorbeeld.nl"
            confirmButtonClass="danger"
            confirmButtonText="Verwijder mijn account"
            onCancel={() =>
              this.setState({removeAccountInputEmailVisible: false})
            }
            onConfirm={this.removeAccount}
          />
        )}
      </>
    );
  }

  handleNotAuthenticated = () => {
    this.props.showNotification(
      'U bent niet ingelogd.',
      NotificationType.ERROR,
    );
    this.props.history.push('/login');
  };

  confirmRemoveProfile = () => {
    this.setState({removeAccountConfirmVisible: true});
  };

  fetchProfile = () => {
    if (this.props.loggedIn) {
      this.props.getProfile(this.props.account.token).catch((e) => {
        if (e instanceof NotAuthenticatedError) {
          this.handleNotAuthenticated();
        }
      });
    } else {
      this.handleNotAuthenticated();
    }
  };

  removeAccount = async (email: string) => {
    const {account, logout, showNotification} = this.props;
    const result = await api.profile.delete(account.token, email);
    if (!result.isSuccess) {
      if (result.message === 'The provided email did not match') {
        showNotification(
          'Incorrect e-mailadres ingevoerd',
          NotificationType.ERROR,
        );
      }
      return;
    }
    this.setState({removeAccountConfirmVisible: false});
    this.setState({removeAccountInputEmailVisible: false});

    logout();
    this.props.history.push('/login');
    showNotification('Account verwijderd', NotificationType.SUCCESS);
  };
}

const mapStateToProps = (state: AppState) => {
  return {
    account: state.account,
    loggedIn: state.account.loggedIn,
    allergies: state.account.allergies,
  };
};

export default connect(mapStateToProps, {
  showNotification,
  getProfile,
  logout,
  updateProfile,
  setHideAllergies,
})(ProfileScreen);
