import RestaurantLabel from '../../models/restaurantLabel';
import RestaurantMenuItem from '../../models/restaurantMenuItem';
import Filter from './filter';

export const ADD_FILTER = 'SEARCH.ADD_FILTER';
export const REMOVE_FILTER = 'SEARCH.REMOVE_FILTER';
export const GET_LABELS = 'SEARCH.GET_LABELS';
export const SEARCH_MENU_RESULT = 'SEARCH.SEARCH_MENU_RESULT';

export type AddFilterAction = {
  type: typeof ADD_FILTER;
  payload: Filter;
};

export type RemoveFilterAction = {
  type: typeof REMOVE_FILTER;
  payload: Filter;
};

export type LabelsRetrievedAction = {
  type: typeof GET_LABELS;
  payload: Array<RestaurantLabel>;
};

export type MenuItemsSearchResultAction = {
  type: typeof SEARCH_MENU_RESULT;
  payload: Array<RestaurantMenuItem>;
};

export type SearchActions =
  | AddFilterAction
  | RemoveFilterAction
  | MenuItemsSearchResultAction
  | LabelsRetrievedAction;
