import classify from '../../../utils/classify';
import React from 'react';
import './textarea.style.scss';

type Props = {
  className?: string;
} & React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const TextArea: React.FC<Props> = (props: Props) => {
  return <textarea className={classify('textarea', props)} {...props} />;
};

TextArea.defaultProps = {
  rows: 10,
};

export default TextArea;
