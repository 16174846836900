import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {ReactComponent as BackIcon} from '../../assets/icons/back.svg';
import Input from '../ui-base/input/input.component';
import React, {ChangeEvent, useCallback} from 'react';
import {useHistory} from 'react-router';
import './search.toolbar.style.scss';
import Bg from '../../utils/bg';

type Props = {
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
};

const SearchToolbar: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const {onSearch, searchValue} = props;
  return (
    <>
      <Bg bg="bg-white" />
      <div className="search_toolbar">
        <button className="search_toolbar_button" onClick={goBack}>
          <BackIcon width={20} height={20} fill="#E7AC5C" />
        </button>
        <Input
          autoFocus={true}
          placeholder="Gerechten, restaurants of keuken"
          className="search_toolbar_input"
          onChange={onSearch}
          value={searchValue}
          noUnderline
        />
        <button className="search_toolbar_button" onClick={goBack}>
          <CloseIcon width={14} height={14} fill="#E7AC5C" />
        </button>
      </div>
    </>
  );
};

export default SearchToolbar;
