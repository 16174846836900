import React from 'react';
import './category.header.style.scss';

type Props = {
    title: string;
    description?: string;
}

const CategoryHeader = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
    const { title, description } = props;

    return (
        <div ref={ref} className={`category_header ${!description && 'single'}`}>
            <h1 className={`category_header_title ${!description && 'single'}`}>
                {title}
            </h1>
            {description && <h2 className="category_header_description">
                {description}
            </h2>}
        </div>
    );
})

export default CategoryHeader;