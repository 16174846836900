import React, {Component} from 'react';
import OccupationGroup from '../../../models/occupationGroup';
import RestaurantOccupationView from '../restaurant/restaurant.view';
import './group.styles.scss';
import RestaurantOccupation from '../../../models/restaurantOccupation';

type Props = {
  occupationGroup: OccupationGroup;
  onRestaurantSelected: (selectedRestaurant: RestaurantOccupation) => void;
};

export class OccupationGroupView extends Component<Props> {
  state = {
    showContents: true,
  };

  renderRestaurant = (restaurant: RestaurantOccupation, index: number) => {
    const {occupationGroup, onRestaurantSelected} = this.props;
    const isLast = occupationGroup.restaurants.length - 1 === index;

    return (
      <RestaurantOccupationView
        className={isLast ? '' : 'border-bottom'}
        occupation={restaurant}
        onPress={() => onRestaurantSelected(restaurant)}
      />
    );
  };

  render() {
    const {showContents} = this.state;
    const {occupationGroup} = this.props;
    let indicatorStyle = '';
    if (occupationGroup.totalOccupationPercentage > 80) {
      indicatorStyle = 'high';
    } else if (occupationGroup.totalOccupationPercentage > 60) {
      indicatorStyle = 'medium';
    }

    return (
      <div className="occupation-group">
        <div className="group-header" onClick={() => this.setState({showContents: !showContents})}>
          <span>{occupationGroup.name}</span>
          <span
            className={
              'occupation-indicator ' + indicatorStyle
            }>{`${occupationGroup.totalOccupationPercentage}% bezet`}</span>
        </div>
        {showContents && occupationGroup.restaurants.map(this.renderRestaurant)}
      </div>
    );
  }
}

export default OccupationGroupView;
