import React, {Component} from 'react';
import Button from '../../../components/ui-base/button/button.component';
import './reservation-button.scss';

type Props = {
  onClick: () => void;
};

export class ReservationButton extends Component<Props> {
  render() {
    const {onClick} = this.props;
    return (
      <Button
        className="reservation-button full-width"
        text="Reserveren"
        onClick={onClick}
      />
    );
  }
}

export default ReservationButton;
