import { loginFacebook } from '../../redux/actions/account.actions';
import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { showNotification } from '../../redux/actions/notifications.actions';
import { NotificationType } from '../../models/notification';

type Props = {
    loginFacebook: (token: string | string[]) => Promise<boolean>,
    showNotification: (
        message: string,
        type: NotificationType,
        duration?: number,
    ) => Promise<void>;
} & RouteComponentProps

class FacebookCallback extends Component<Props> {

    componentDidMount() {
        const obj = queryString.parse(this.props.location.search);
        if (obj.lw_token) {
            this.login(obj.lw_token);
        }
    }

    login = async (token: string | string[]) => {
        const success = await this.props.loginFacebook(token);

        if (success) {
            return this.props.history.push('/profile');
        } else {
            this.props.showNotification(
                'Er ging iets mis met inloggen.',
                NotificationType.ERROR,
                5000,
            );
            return this.props.history.push('/login');
        }
    };

    render = () => {
        return (
            <p className="mt-20 ml-20">U wordt ingelogd...</p>
        );
    };
}

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { loginFacebook, showNotification })(FacebookCallback);
