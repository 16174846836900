import classNames from 'classnames';
import InputGroup from '../input-group/input-group.component';
import Label from '../label/label.component';
import './label-value.styles.scss';

type Props = {
  label: string;
  value: string;
  highlighted?: boolean;
  onValueClick?: () => void;
};

const LabelValueGroup: React.FC<Props> = ({
  label,
  value,
  highlighted,
  onValueClick,
}: Props) => {
  return (
    <InputGroup className={classNames('label-value', {highlighted})}>
      <Label>{label}</Label>
      <p
        className={classNames({clickable: onValueClick !== undefined})}
        onClick={onValueClick}>
        {value}
      </p>
    </InputGroup>
  );
};
export default LabelValueGroup;
