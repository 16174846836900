import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../redux/reducers/root.reducer';
import OccupationGroup from '../../models/occupationGroup';
import Toolbar from '../../components/toolbar/toolbar.component';
import Content from '../../components/content/content.component';
import {isMobileOrTablet} from '../../utils/isMobileOrTablet';
import AspectRatioContainer from '../../components/aspect-ratio-container/aspect-ratio-container.component';
import {RouteComponentProps} from 'react-router-dom';

import header from '../../assets/images/header.png';
import headerDesktop from '../../assets/images/header_desktop.jpg';
import {ReactComponent as ScanIcon} from '../../assets/icons/scan.svg';
import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg';
import OccupationGroupView from './group/group.view';
import './occupation-groups.styles.scss';
import RestaurantOccupation from '../../models/restaurantOccupation';
import Restaurant from '../../models/restaurant';
import {ReactComponent as FilterIcon} from '../../assets/icons/arrow-up.svg';

type Props = {
  account: any;
  groups: Array<OccupationGroup>;
  restaurants: Array<Restaurant>;
} & RouteComponentProps;

class OccupationGroupScreen extends Component<Props> {
  menuItems = [
    {
      id: 'profile',
      icon: ProfileIcon,
    },
    {
      id: 'scan',
      icon: ScanIcon,
      className: isMobileOrTablet() ? '' : 'dont-show-on-desktop',
    },
  ];

  state = {
    sortOrder: true,
  };

  onMenuItemClicked = (item: any) => {
    const {account} = this.props;
    if (item.id === 'scan') {
      this.props.history.push('/scanner');
    } else if (item.id === 'profile') {
      if (account.loggedIn) {
        this.props.history.push('/profile');
      } else {
        this.props.history.push('/login');
      }
    }
  };

  onRestaurantSelected = (occupation: RestaurantOccupation) => {
    const {history, restaurants} = this.props;
    const restaurant = restaurants.find((x) => x.id === occupation.id)!!!;
    history.push(`/restaurant/${restaurant.generateSlug()}`);
  };

  _renderOccupationGroup = (group: OccupationGroup) => {
    return (
      <OccupationGroupView
        occupationGroup={group}
        onRestaurantSelected={this.onRestaurantSelected}
      />
    );
  };

  toggleSortOrder = () => {
    this.setState({sortOrder: !this.state.sortOrder});
  };

  parseGroups = () => {
    const {groups} = this.props;
    return groups
      .sort((a, b) => this.state.sortOrder
          ? a.totalOccupationPercentage - b.totalOccupationPercentage
          : b.totalOccupationPercentage - a.totalOccupationPercentage
      )
      .map((x) => this._renderOccupationGroup(x));
  };

  render() {
    const {sortOrder} = this.state;
    return (
      <>
        <Toolbar
          title="Restaurants"
          showMenuIcon={true}
          items={this.menuItems}
          onItemClicked={this.onMenuItemClicked}
        />
        <Content toolbar={true}>
          {isMobileOrTablet() ? (
            <AspectRatioContainer
              ratio="38.4"
              containerClassnames="dont-show-on-desktop">
              <img src={header} alt="header" width="100%" height="100%" />
              <div className="sorting-wrapper">
              <button
                className={`toolbar_button`}
                onClick={this.toggleSortOrder}>
                <FilterIcon className={`sorting-icon ${sortOrder ? 'reverse' : ''}`} />
              </button>
              </div>
            </AspectRatioContainer>
          ) : (
            <div
              style={{
                height: '250',
                backgroundImage: 'url(' + headerDesktop + ')',
                backgroundPosition: 'center',
              }}
            />
          )}
          <div className="full-width">
            {this.parseGroups()}
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    account: state.account,
    groups: state.occupation.groups.filter((x) => x.restaurants.length > 0),
    restaurants: state.restaurants.restaurants,
  };
};

export default connect(mapStateToProps, {})(OccupationGroupScreen);
