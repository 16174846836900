import {useEffect, useState} from 'react';

type UserLocation = {
  latitude: number;
  longitude: number;
};

const useUserLocation = () => {
  const [userLocation, setUserLocation] = useState<UserLocation>();
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position: any) => {
      setUserLocation({
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
      });
    });
  }, []);
  return userLocation;
};

export default useUserLocation;
