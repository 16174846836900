export const useReservationQuestion = (step: number) => {
  switch (step) {
    case 1:
      return 'Met hoeveel personen wil je komen?';
    case 2:
      return 'Wanneer wil je dat we een tafeltje voor je vrijhouden?';
    case 3:
      return 'En op welk tijdstip wil je aanschuiven?';
    case 4:
      return 'Is er een plekje in onze zaak waar je het liefst zou zitten?';
    case 5:
      return 'Vul onderstaande gegevens in om de reservering af te ronden.';
    default:
      return 'Met hoeveel personen wil je komen?';
  }
};
