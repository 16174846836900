import React, {Component} from 'react';
import Helmet from 'react-helmet';

export default class Bg extends Component {

    render() {
        const {bg} = this.props;

        return (
            <Helmet htmlAttributes={{class: bg}}/>
        );
    }
}
