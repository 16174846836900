import React, { ReactNode } from 'react';
import './content.style.scss';
import classNames from 'classnames';

type Props = {
    children: ReactNode;
    className?: string;
    toolbar?: Boolean;
}

const Content: React.FC<Props> = ({ className, toolbar, children }) => {
    const classes = toolbar ? 'content with_toolbar' : 'content';
    return (
        <div className={classNames(classes, className)}>
            {children}
        </div>
    );
}

export default Content;