import React from 'react';
import { Button } from '../ui-base';
import {ReactComponent as FacebookIcon} from '../../assets/icons/facebook.svg';

type Props = {
  onClick: () => void;
};

const FacebookButton: React.FC<Props> = (props: Props) => {
  return (
    <Button
      text="Inloggen met Facebook"
      icon={<FacebookIcon className="icon" />}
      className="mt-10 fb"
      onClick={props.onClick}
    />
  );
};

export default FacebookButton;
