import {ReactComponent as SulphurDioxideIcon} from '../../assets/icons/sulphur_dioxide.svg';
import {ReactComponent as ShellfishIcon} from '../../assets/icons/shellfish.svg';
import {ReactComponent as MolluscsIcon} from '../../assets/icons/molluscs.svg';
import {ReactComponent as PeanutsIcon} from '../../assets/icons/peanuts.svg';
import {ReactComponent as SulfiteIcon} from '../../assets/icons/sulfite.svg';
import {ReactComponent as MustardIcon} from '../../assets/icons/mustard.svg';
import {ReactComponent as SesameIcon} from '../../assets/icons/sesame.svg';
import {ReactComponent as LupineIcon} from '../../assets/icons/lupine.svg';
import {ReactComponent as CeleryIcon} from '../../assets/icons/celery.svg';
import {ReactComponent as GrainsIcon} from '../../assets/icons/grains.svg';
import {ReactComponent as SoyaIcon} from '../../assets/icons/soya.svg';
import {ReactComponent as FishIcon} from '../../assets/icons/fish.svg';
import {ReactComponent as MilkIcon} from '../../assets/icons/milk.svg';
import {ReactComponent as NutsIcon} from '../../assets/icons/nuts.svg';
import {ReactComponent as EggIcon} from '../../assets/icons/egg.svg';

const allergies = [
    {icon: ShellfishIcon, name: 'Schaaldieren', color: '#E82A5A', id: 'schaaldieren'},
    {icon: PeanutsIcon, name: 'Pinda', color: '#CD6A41', id: 'pinda'},
    {icon: SoyaIcon, name: 'Soya', color: '#70A745', id: 'soja'},
    {icon: FishIcon, name: 'Vis', color: '#0084CF', id: 'vis'},
    {icon: SesameIcon, name: 'Sesam', color: '#DFAF62', id: 'sesamzaad'},
    {icon: MilkIcon, name: 'Melk', color: '#54C5F5', id: 'melk'},
    {icon: SulphurDioxideIcon, name: 'Zwaveldioxide', color: '#8B60A9', id: 'zwaveldioxide'},
    {icon: MolluscsIcon, name: 'Weekdieren', color: '#A19187', id: 'weekdieren'},
    {icon: SulfiteIcon, name: 'Sulfiet', color: '#D16A92', id: 'sulfiet'},
    {icon: MustardIcon, name: 'Mosterd', color: '#E68700', id: 'mosterd'},
    {icon: LupineIcon, name: 'Lupine', color: '#D16A92', id: 'lupine'},
    {icon: CeleryIcon, name: 'Selderij', color: '#009F60', id: 'selderij'},
    {icon: GrainsIcon, name: 'Glutenbevattende Granen', color: '#BF913F', id: 'granen'},
    {icon: EggIcon, name: 'Eieren', color: '#FF9000', id: 'eieren'},
    {icon: NutsIcon, name: 'Noten', color: '#885127', id: 'noten'}
];

export default allergies;
