import L, {LatLng} from 'leaflet';
import React, {useEffect, useState} from 'react';
import {Marker} from 'react-leaflet';

import LocationIconOrange from '../../assets/icons/location-pin-orange.svg';
import LocationIconRed from '../../assets/icons/location-pin-red.svg';
import LocationIconGreen from '../../assets/icons/location-pin-green.svg';
import Restaurant from '../../models/restaurant';
import RestaurantOccupation from '../../models/restaurantOccupation';
import {RestaurantPopup} from './restaurant-popup';

type Props = {
  restaurant: Restaurant;
  occupation?: RestaurantOccupation;
};

export const RestaurantMarker: React.FC<Props> = (props: Props) => {
  const [markerIcon, setMarkerIcon] = useState<L.Icon | undefined>(
    L.icon({
      iconUrl: LocationIconOrange,
      iconSize: new L.Point(36, 36),
      iconAnchor: new L.Point(18, 36),
    }),
  );

  const location = new LatLng(
    parseFloat(props.restaurant.latitude),
    parseFloat(props.restaurant.longitude),
  );

  useEffect(() => {
    var icon;
    if (props.occupation) {
      if (props.occupation.occupationPercentage > 80) {
        icon = LocationIconRed;
      } else if (props.occupation.occupationPercentage > 60) {
        icon = LocationIconOrange;
      } else {
        icon = LocationIconGreen;
      }
    } else {
      icon = LocationIconOrange;
    }
    setMarkerIcon(
      L.icon({
        iconUrl: icon,
        iconSize: new L.Point(36, 36),
        iconAnchor: new L.Point(18, 36),
      }),
    );

    return () => {};
  }, [props.occupation]);

  return (
    <Marker icon={markerIcon} position={location}>
      <RestaurantPopup restaurant={props.restaurant} />
    </Marker>
  );
};
