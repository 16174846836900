import LoadingIndicator from '../../components/loading-indicator/loading.indicator.component';
import { showNotification } from '../../redux/actions/notifications.actions';
import Toolbar from '../../components/toolbar/toolbar.component';
import Content from '../../components/content/content.component';
import { Component } from 'react';
import './forgot-password.style.scss';
import api from '../../services/api';
import { connect } from 'react-redux';
import Bg from '../../utils/bg';
import { Button, Input, InputGroup, Label } from '../../components/ui-base';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../redux/reducers/root.reducer';
import { NotificationType } from '../../models/notification';

type ReduxProps = {
  loggedIn: boolean;
}

type Props = {
  showNotification: (
    message: string,
    type: NotificationType,
    duration?: number,
  ) => Promise<void>;
} & RouteComponentProps & ReduxProps;

class ForgotPassword extends Component<Props> {
  state = {
    email: '',
    loading: false,
  };

  onEmailInputChanged = (event: Event) => {

  };

  resetPassword = async () => {
    let { email } = this.state;
    email = email.toLowerCase();

    if (!this.validateEmail(email)) {
      this.props.showNotification('Vul een valide e-mailadres in', NotificationType.ERROR);
      return;
    }

    this.setState({ loading: true });

    const success = await api.resetPassword(email);

    this.setState({ loading: false });

    if (success) {
      this.props.showNotification(
        'Mail verstuurd! Controleer uw inbox.',
        NotificationType.SUCCESS,
        5000,
      );
    } else {
      this.props.showNotification('Mail is niet bekend.', NotificationType.ERROR);
    }
  };

  validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  _renderContent = () => {
    const { email } = this.state;

    return (
      <div className="forgot_password_content full-width">
        <InputGroup>
          <Label>E-mailadres</Label>
          <Input
            type="email"
            placeholder="Bijv. email@gmail.com"
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
          />
        </InputGroup>
        <Button
          text="Wachtwoord herstellen"
          className="mt-20"
          onClick={this.resetPassword}
        />
      </div>
    );
  };

  render() {
    const { loggedIn } = this.props;
    const { loading } = this.state;

    if (loggedIn) {
      setTimeout(() => this.props.history.replace('/profile'), 0);
      return <></>;
    }

    return (
      <>
        <Toolbar backLink="/login" title="Wachtwoord vergeten" />
        <Bg bg="bg-white" />

        <Content toolbar={true}>
          <div className="login_header" />

          {!loading && this._renderContent()}
          <LoadingIndicator isLoading={loading} />
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState): ReduxProps => {
  return {
    loggedIn: state.account.loggedIn,
  };
};

export default connect(mapStateToProps, { showNotification })(ForgotPassword);
