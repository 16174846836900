import {TransitionGroup} from 'react-transition-group';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import './notification.style.scss';
import NotificationsPortal from './notifications-portal.component';
import NotificationComponent from './notification/notification.component';
import Notification from '../../models/notification';
import {AppState} from '../../redux/reducers/root.reducer';

type ReduxProps = {
  notifications: Array<Notification>;
};

type Props = {} & ReduxProps;

class Notifications extends Component<Props> {
  _renderItem = (notification: Notification) => {
    return (
      <NotificationComponent
        key={notification.id}
        notification={notification}
      />
    );
  };

  render() {
    const {notifications} = this.props;

    const items = notifications.map((notification) =>
      this._renderItem(notification),
    );

    return (
      <NotificationsPortal>
        <TransitionGroup className="notifications">{items}</TransitionGroup>
      </NotificationsPortal>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    notifications: state.notifications,
  };
};

export default connect(mapStateToProps, {})(Notifications);
