import Formatters from '../../utils/formatters';
import { InputGroup, Label, Select } from '../ui-base';

type Props = {
  currentValue: 'pin' | 'cash' | 'ideal';
  onSelectionChange: (selectedPaymentMethod: 'pin' | 'cash' | 'ideal') => void;
  config: {
    iDealEnabled: boolean;
    cashEnabled: boolean;
    pinEnabled: boolean;
    iDealCost: number | string;
  };
};

const PaymentMethodSelector = (props: Props) => {
  return (
    <InputGroup>
      <Label>Betaalmethode</Label>
      <Select
        value={props.currentValue}
        onChange={(event) =>
          props.onSelectionChange(
            event.target.value as 'pin' | 'cash' | 'ideal',
          )
        }
        className="full-width">
        <option value="" disabled selected>
          Kies betaalmethode
        </option>
        <option
          disabled={!props.config.iDealEnabled}
          value="ideal">{`iDeal (+${Formatters.formatCurrency(
          props.config.iDealCost,
        )})`}</option>
        <option disabled={!props.config.pinEnabled} value="pin">
          Pin
        </option>
        <option disabled={!props.config.cashEnabled} value="cash">
          Contant
        </option>
      </Select>
    </InputGroup>
  );
};

export default PaymentMethodSelector;
