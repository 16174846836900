import React, {useCallback, useState} from 'react';
import {Allergy} from './allergies.component';
import './allergy-item.style.scss';

type Props = {
  allergy: Allergy;
};

const AllergyItem: React.FC<Props> = ({allergy}) => {
  const [showPopup, setShowPopup] = useState(false);
  const Icon = allergy.icon;

  const togglePopup = useCallback(() => {
    setShowPopup(!showPopup);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  }, [showPopup, setShowPopup]);

  return (
    <div className="icon-wrapper" onClick={togglePopup}>
      <Icon className="icon" fill={allergy.color} stroke={allergy.color} />
      {showPopup && (
        <div className="pop-up">
          {allergy.name}
        </div>
      )}
    </div>
  );
};
export default AllergyItem;
