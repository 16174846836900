import React from 'react';
import classNames from 'classnames';
import './typography.scss';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type?: any;
  color?: string;
  className?: string;
  headerClass?: string;
};

const Typography: React.FC<Props> = ({
  headerClass,
  children,
  type,
  className,
}) => {
  if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'].includes(type)) {
    let styling = classNames(className, headerClass);
    let CustomTag = type;
    if (type === 'h7') {
      CustomTag = 'h6';
      styling = classNames(className, 'h7');
    }
    return <CustomTag className={`${styling}`}>{children}</CustomTag>;
  }
  if (['label', 'label large'].includes(type)) {
    const styling = classNames(type, className);
    return <div className={styling}>{children}</div>;
  }
  const styling = classNames(type, className);
  return <p className={styling}>{children}</p>;
};

export default Typography;
