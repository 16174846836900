import L, {ErrorEvent, Map} from 'leaflet';
import React, {useEffect, useMemo, useState} from 'react';
import {MapContainer, TileLayer} from 'react-leaflet';
import {connect} from 'react-redux';

import Restaurant from '../../models/restaurant';
import RestaurantOccupation from '../../models/restaurantOccupation';
import {AppState} from '../../redux/reducers/root.reducer';
import {GPSState} from '../../screens/map/gps-state';
import useWindowSize from '../../utils/hooks/useWindowResize';
import Legend from './legend/legend.component';
import {RestaurantMarker} from './restaurant-marker';
import {UserLocationMarker} from './user-location-marker';

export const LOCATION_ERROR_PERMISSION_DENIED = 1;
export const LOCATION_ERROR_LOCATION_ERROR = 2;
export const LOCATION_ERROR_TIMEOUT = 3;

type ReduxProps = {
  occupations: Array<RestaurantOccupation>;
  restaurants: Array<Restaurant>;
};

type Props = {
  initialCenter: L.LatLng;
  initialZoom: number;
  gpsState: GPSState;
  onDrag: () => void;
  onLocationError: (locationError: ErrorEvent) => void;
} & ReduxProps;

const RestaurantsMap: React.FC<Props> = (props: Props) => {
  const [map, setMap] = useState<Map>();
  const size = useWindowSize();

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={props.initialCenter}
        zoom={props.initialZoom}
        scrollWheelZoom={false}
        whenCreated={setMap}
        style={{
          height: size.height ? size.height - 64 : window.innerHeight - 64,
        }}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.restaurants.map((restaurant) => (
          <RestaurantMarker
            key={restaurant.id}
            restaurant={restaurant}
            occupation={props.occupations.find((x) => x.id === restaurant.id)}
          />
        ))}
        <UserLocationMarker gpsState={props.gpsState} />
        <Legend />
      </MapContainer>
    ),
    [
      props.initialCenter,
      props.initialZoom,
      props.restaurants,
      props.occupations,
      size.height,
      props.gpsState,
    ],
  );

  useEffect(() => {
    if (map) {
      map.addEventListener('dragstart', props.onDrag);
      map.addEventListener('locationerror', props.onLocationError);
    }
    return () => {
      map?.removeEventListener('dragstart', props.onDrag);
      map?.removeEventListener('locationerror', props.onLocationError);
    };
  }, [map, props.onDrag, props.onLocationError]);

  return displayMap;
};

const mapStateToProps = (state: AppState) => {
  return {
    restaurants: state.restaurants.restaurants,
    occupations: state.occupation.ungroupedRestaurants,
  };
};

export default connect(mapStateToProps, {}, null, {forwardRef: true})(
  RestaurantsMap,
);
