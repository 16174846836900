import React from 'react';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {ReactComponent as CheckIcon} from '../../assets/icons/check.svg';
import { Card, CardContent, Checkbox } from '../ui-base';
import './allergies.list.item.style.scss';

type Props = {
  active: boolean;
  name: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  color: string;
  readonly: boolean;
  onClick?: (newValue: boolean) => void;
};

const AllergiesListItem: React.FC<Props> = (props: Props) => {
  const targetColor = props.readonly && !props.active ? '#BDC3C7' : props.color;
  const colorStyle = {color: targetColor};
  const Icon = props.icon;

  return (
    <Card
      className={`allergy-row${props.active ? ' active' : ''}`}
      onClick={() =>  props.onClick && props.onClick(!props.active)}
    >
      <CardContent className="allergy-row-content">
        <Icon className="icon" fill={targetColor} stroke={targetColor} />
        <div className="allergy-name" style={colorStyle}>
          {props.name}
        </div>
        {props.readonly && props.active && (
          <CheckIcon className="right_icon" fill="#183F57" />
        )}
        {props.readonly && !props.active && (
          <CloseIcon
            className="right_icon_close"
            style={{width: 16, height: 16}}
            fill="#BDC3C7"
          />
        )}
        {!props.readonly && (
          <Checkbox checked={props.active} onChecked={props.onClick} />
        )}
      </CardContent>
    </Card>
  );
};

export default AllergiesListItem;
